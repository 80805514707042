import { Component, OnInit } from '@angular/core';
import { registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-auth-template-register-candidate-address-contact',
  templateUrl: './auth-template-register-candidate-address-contact.component.html',
  styleUrls: ['./auth-template-register-candidate-address-contact.component.scss'],
})
export class AuthTemplateRegisterCandidateAddressContactComponent implements OnInit {

  constructor() { }

  titleHeader = registerCandidate.titleRegister
  nameIcon = registerCandidate.nameIcon

  ngOnInit() {}

}
