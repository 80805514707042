import { Component, Input, OnInit } from '@angular/core';
import { appConstanst } from '../../constant/app-constans';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {

  constructor() { }

  @Input() srcImage = 'https://img.ecologiahoy.com/2016/08/Mamiferos-1024x640.jpg';
  @Input() nameUser = '';
  @Input() business = '';
  @Input() dominantHand = '';
  titleDominantHand = appConstanst.titleDominantHand

  ngOnInit() {}

}
