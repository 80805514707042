import { Component, OnInit } from '@angular/core';
import { habeasData, registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-auth-template-accept-habeas-data',
  templateUrl: './auth-template-accept-habeas-data.component.html',
  styleUrls: ['./auth-template-accept-habeas-data.component.scss'],
})
export class AuthTemplateAcceptHabeasDataComponent implements OnInit {

  constructor() { }

  titleHeader = registerCandidate.titleRegister;  
  titelHabeasData = habeasData.title
  paragraph_1 = habeasData.paragraph_1
  paragraph_2 = habeasData.paragraph_2
  paragraph_3 = habeasData.paragraph_3

  accept = 'Acepto'
  notAccept = 'No acepto'
  goBack = registerCandidate.goBack
  pathGoBack = '/auth/register'
  pathNotAccept = '/auth/home'

  ngOnInit() {}

  redirectGoBack(){
    location.href = this.pathGoBack
  }

  redirectNotAccept(){
    location.href = this.pathNotAccept
  }

}
