import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelComponent } from './atoms/label/label.component';
import { InputComponent } from './atoms/input/input.component';
import { ImageComponent } from './atoms/image/image.component';
import { FormInputTextComponent } from './molecules/form-input-text/form-input-text.component';
import { TitleComponent } from './atoms/title/title.component';
import { ButtonComponent } from './atoms/button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonCardComponent } from './atoms/button-card/button-card.component';
import { CardsComponent } from './atoms/cards/cards.component';
import { UserDetailComponent } from './atoms/user-detail/user-detail.component';
import { EmailInputComponent } from './atoms/email-input/email-input.component';
import { IonicModule } from '@ionic/angular';
import { InputSelectComponent } from './atoms/input-select/input-select.component';
import { InputPlaceholderComponent } from './atoms/input-placeholder/input-placeholder.component';
import { InputPlaceholderColorComponent } from './atoms/input-placeholder-color/input-placeholder-color.component';
import { TextareaComponent } from './atoms/textarea/textarea.component';
import { CardsSelectCandidateComponent } from './atoms/cards-select-candidate/cards-select-candidate.component';
import { InputSearchComponent } from './atoms/input-search/input-search.component';
import { InputDateComponent } from './atoms/input-date/input-date.component';
import { DescriptionSesionComponent } from './atoms/description-sesion/description-sesion.component';



@NgModule({
  declarations: [
    LabelComponent,
    InputComponent,
    InputSelectComponent,
    InputPlaceholderComponent,
    InputPlaceholderColorComponent,
    EmailInputComponent,
    ImageComponent,
    TitleComponent,
    FormInputTextComponent,
    ButtonComponent,
    ButtonCardComponent,
    CardsComponent,
    UserDetailComponent,
    TextareaComponent,
    CardsSelectCandidateComponent,
    InputSearchComponent,
    InputDateComponent,
    DescriptionSesionComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

  ],
  exports:[
    LabelComponent,
    InputComponent,
    InputSelectComponent,
    InputPlaceholderComponent,
    InputPlaceholderColorComponent,
    EmailInputComponent,
    ImageComponent,
    TitleComponent,
    FormInputTextComponent,
    ButtonComponent,
    ButtonCardComponent,
    CardsComponent,
    UserDetailComponent,
    TextareaComponent,
    CardsSelectCandidateComponent,
    InputSearchComponent,
    InputDateComponent,
    DescriptionSesionComponent,
    IonicModule
  ]
})
export class AppCommonModule { }
