import { Component, OnInit } from '@angular/core';
import { appConstanst } from 'src/app/app-common/constant/app-constans';

@Component({
  selector: 'app-feature-recovery',
  templateUrl: './feature-recovery.component.html',
  styleUrls: ['./feature-recovery.component.scss'],
})
export class FeatureRecoveryComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
