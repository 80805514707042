import { Component, OnInit } from '@angular/core';
import { appConstanst } from 'src/app/app-common/constant/app-constans';

@Component({
  selector: 'app-auth-template-recovery',
  templateUrl: './auth-template-recovery.component.html',
  styleUrls: ['./auth-template-recovery.component.scss'],
})
export class AuthTemplateRecoveryComponent implements OnInit {

  src = '';
  text_1 = '';
  text_2 = '';

  titleRecoveryPassword = 'Recuperar contraseña';
  redirectLogin = '/auth/login';

  constructor() { }

  ngOnInit() {
    this.src = appConstanst.logoUrl
    this.text_1 = 'Recupera tu contraseña';
    this.text_2 = 'Ingresa tu contraseña y te enviaremos un mensaje para que recuperes tu contraseña';
  }

}
