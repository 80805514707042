import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { FeatureLoginComponent } from '../feature-login/feature-login.component';

@Component({
  selector: 'app-feature-home',
  templateUrl: './feature-home.component.html',
  styleUrls: ['./feature-home.component.scss'],
})
export class FeatureHomeComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    // localStorage.setItem('data',JSON.stringify(this.test))
    // console.log(JSON.parse(localStorage.getItem('data')))
  }

}
