import { Component, OnInit } from '@angular/core';
import { registerCandidate } from '../domain/constants/login-constants';

@Component({
  selector: 'app-feature-tests-candidates',
  templateUrl: './feature-tests-candidates.component.html',
  styleUrls: ['./feature-tests-candidates.component.scss'],
})
export class FeatureTestsCandidatesComponent implements OnInit {

  constructor() { }

  titleHeader = 'Candidatos seleccionados'
  nameIcon = registerCandidate.nameIcon

  candidates = [
    {
      name : 'Jhoan Rios Vergara 1',
      value : '1'
    },
    {
      name : 'Jhoan Rios Vergara 2',
      value : '2'
    },
    {
      name : 'Jhoan Rios Vergara 3',
      value : '3'
    },
    {
      name : 'Jhoan Rios Vergara 4',
      value : '4'
    },
    {
      name : 'Jhoan Rios Vergara 5',
      value : '5'
    },
    {
      name : 'Jhoan Rios Vergara 6',
      value : '6'
    },
  ]

  ngOnInit() {}

  changeCandidate(numCandidate: string){
    console.log(this.candidates[numCandidate].name)

  }

}
