import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { element } from 'protractor';
import { appConstanst } from 'src/app/app-common/constant/app-constans';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { register } from '../../constants/contatns';
import { habeasData, registerCandidate } from '../../constants/login-constants';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';


@Component({
  selector: 'app-form-register-candidate',
  templateUrl: './form-register-candidate.component.html',
  styleUrls: ['./form-register-candidate.component.scss'],
})
export class FormRegisterCandidateComponent implements OnInit {

//   constructor(
//     private api : ApiService,
//     private tokenService : TokenService,
//     private alertController : AlertController,
//     private barcodeScanner : BarcodeScanner,
//     private loadingController : LoadingController,
    
//   ) { }
  
//   ngOnInit() {
//     // this.showLoading()
//     this.tokenService.validToken()
//     this.getDocumentTypes()
//     this.getGender()
//     this.getTestsGroup()
//     this.getBusiness()
//     this.getDepartaments()
//     this.getDominantHands()
//     this.getAcademicLevel()
//     this.loadData()
//     this.date = new Date().toISOString()
//     this.bodyPostSesionFilter.filterDate = this.date.substring(0,10)
//     this.getSesions()
//   }

//   //Variables
//   next = 'Siguiente';
//   goBack = 'Regresar';
//   pageHome = '/auth/home';
//   pathDataTreatmen:string = 'auth/accept-habeas-data';
//   pathDocumentType:string = '/document_type';
//   goBackPage = '/auth/information-basic';
//   nextPage = '/auth/information-academic';
//   pathGetTestGroup:string = '/test_group';
//   pathGetTests:string = '/tests';
//   tests:any;
//   pathPostUser:string = '/user';
//   pathPostAttempt = '/test_attempt';
//   pathPostAttemptTests = '/attempt_tests';
//   pathGetBusiness:string = '/business';
//   pathGetGender:string = '/gender';
//   genders:any;
//   pathGetDepartament:string = '/departament';
//   departaments:any;
//   pathGetMunicipaly:string = '/municipality/departament';
//   municipalys:any;
//   pathDominantHand:string = '/dominant_hand'
//   dominantHands:any;
//   pathAcademicLevel:string = '/academic_level'
//   academicLevels:any;
//   pathSesions : string = '/sessions_filter'
//   sessions : any = [];
//   pathUserSesion : string = '/sesion_user_element'
//   enableSave = false
//   auxReloading:number = 0

//   //Body json

//   registerUser : any;
//   bodyPostAttempt : any;
//   bodyPostAttemptTests : any;
//   aditionalData:any = {
//     password : null,
//     trash : null,
//     enable : null,
//     lastName : null,
//     profileId : registerCandidate.profileId
//   }

//   bodyPostSesionFilter = {
//     filterDate : '',
//   }

//   titleInformationBasic = registerCandidate.titleInformationBasic;
//   documentType = registerCandidate.documentType;
//   identificationID = registerCandidate.identificationId;
//   identityCard = registerCandidate.identityCard;
//   specialResidencePermit = registerCandidate.specialResidencePermit;
//   foreignerId = registerCandidate.foreignerId
  
//   documentNumber = registerCandidate.documentNumber;
//   completeName = registerCandidate.completeName;
//   dateBirth = registerCandidate.dateBirth;
//   gender = registerCandidate.gender;
//   familyNucleus = registerCandidate.familyNucleus;
//   titleRegister = registerCandidate.titleRegister;
//   nameImage = registerCandidate.nameImageRegister;

//   registerJson:any;
//   dataTreatment:string = habeasData.titleCheckbox
//   seeDataTreatment:string = habeasData.see
//   documentTypes:any;

//   titleAddresContact = registerCandidate.titleAddresContact;
//   departament = registerCandidate.departament;
//   municipaly = registerCandidate.municipaly;
//   neighborhood = registerCandidate.neighborhood;
//   address = registerCandidate.Address;
//   phone = registerCandidate.phone;
//   emailPage = registerCandidate.email;
//   morphology = registerCandidate.titleMorphology;
//   height = registerCandidate.height;
//   weight = registerCandidate.weight;
//   dominantHand = registerCandidate.dominantHand;
//   left = registerCandidate.left;
//   right = registerCandidate.right

//   data:any;

//   titleInformationAcademic = registerCandidate.titleInformationAcademic;
//   academicLevel = registerCandidate.academicLevel;
//   graduationYear = registerCandidate.graduationYear;
//   graduationInstitution = registerCandidate.graduationInstitution;
//   titleWorkingInformation = registerCandidate.titleWorkingInformation;
//   experience = registerCandidate.experience;
//   companyApply = registerCandidate.companyApply;
//   business = registerCandidate.business;
//   other = registerCandidate.otherBusiness;
//   testToCarried = registerCandidate.testToCarried;
//   validBusiness = registerCandidate.validBusiness;
//   testsSesion = registerCandidate.testsSesion
//   placeholderSesion = registerCandidate.placeholderSesion

//   save = registerCandidate.save;
//   year = new Date().toLocaleDateString('es', {year:"numeric"}).toString();
//   userID:any = '';
//   testGroupID:any = '';

//   token:string;
//   uuidUser:any;
//   uuidAttempt:any;
//   responseBusiness:any;
//   register:any;

//   date : any;
  

//   //Forms
//   formInput = new FormGroup({
//     name : new FormControl(),
//     email : new FormControl(),
//     uuidUserDocumentType : new FormControl(),
//     documentNumber : new FormControl(),
//     phone : new FormControl(),
//     acceptHabeasData : new FormControl(),
//     uuidAcademicLevel : new FormControl(),
//     address : new FormControl(),
//     uuidBusinessLineEntities : new FormControl(),
//     uuidGeoDepartament : new FormControl(),
//     dateBirth : new FormControl(),
//     uuidUserDominantHands : new FormControl(),
//     experience : new FormControl(),
//     familyNucleus : new FormControl(),
//     uuidUserGender : new FormControl(),
//     graduationYear : new FormControl(),
//     graduationInstitution : new FormControl(),
//     height : new FormControl(),
//     weight : new FormControl(),
//     municipaly : new FormControl(),
//     uuidGeoMunicipaly : new FormControl(),
//     testToCarried : new FormControl(),
//     neighborhood : new FormControl(),
//     sesionId : new FormControl()
//   })

//   //Functions
//   code : any;
//   scanner(){
//     this.barcodeScanner.scan({
//       formats : 'PDF_417'
//     }).then(barcodeData => {
//       this.decodePDF417(barcodeData.text)
//      }).catch(err => {
//          console.log('Error', err);
//      });
//   }

//   decodePDF417(data){
//     let nameDecode, documentNumberDecode, dateBirthDecode; 
//     nameDecode = data.substring(100,115).replace(/ /g, '').replace(/\u0000/g, '') + ' ' + data.substring(115, 135).replace(/ /g, '').replace(/\u0000/g, '') + ' ' + data.substring(58, 70).replace(/ /g, '').replace(/\u0000/g, '') + ' ' +  data.substring(70, 100).replace(/ /g, '').replace(/\u0000/g, '')
//     nameDecode = nameDecode.toString()
//     dateBirthDecode = data.substring(152,156) + '-' + data.substring(156,158) + '-' + data.substring(158,160)
//     try{
//       documentNumberDecode = parseInt(data.substring(48, 58)).toString()
//     }
//     catch{
//       documentNumberDecode = data.substring(48,58)
//     }
//     this.formInput.setValue({
//       name : nameDecode,
//       email : null,
//       uuidUserDocumentType : null,
//       phone : null,
//       acceptHabeasData : null,
//       uuidAcademicLevel : null,
//       address : null,
//       uuidBusinessLineEntities : null,
//       uuidGeoDepartament : null,
//       dateBirth : dateBirthDecode,
//       uuidUserDominantHands : null,
//       experience : null,
//       familyNucleus : null,
//       uuidUserGender : null,
//       graduationYear : null,
//       graduationInstitution : null,
//       height : null,
//       weight : null,
//       municipaly : null,
//       uuidGeoMunicipaly : null,
//       testToCarried : null,
//       neighborhood : null,
//       documentNumber : documentNumberDecode,
//       sesionId : null
//     })
//   }

//   getSesions(){
//     this.api.post(this.pathSesions, this.bodyPostSesionFilter, this.tokenService.get())
//     .subscribe((res => {
//       if (res !== appConstanst.not_exist_sesions){
//         this.sessions = res
//       }else{
//         this.sessions = [];
//       }
//       if (this.auxReloading == 1){
//         this.loadingController.dismiss()
//         this.auxReloading = 0
//       }
//     }))
//   }

//   onChangeMunicipaly(){
//     // this.showLoading()
//     this.getMunicipaly(this.formInput.value.uuidGeoDepartament)
//   }

//   testGroupIds(){
//     this.testGroupID = this.formInput.value.testToCarried
//   }

//   addData(){
//     const confirmEmail = {
//       confirmEmail : this.formInput.value.email,
//     }
//     this.registerJson = Object.assign(this.formInput.value, confirmEmail)
//     this.registerJson = Object.assign(this.registerJson, this.aditionalData)
//     // this.registerJson.name = this.formInput.value.name[0].toUpperCase() + this.formInput.value.name.slice(1)
//     // this.registerJson.familyNucleus = this.formInput.value.familyNucleus[0].toUpperCase() + this.formInput.value.familyNucleus.slice(1)
//     // this.registerJson.address = this.formInput.value.address[0].toUpperCase() + this.formInput.value.address.slice(1)
//     // this.registerJson.experience = this.formInput.value.experience[0].toUpperCase() + this.formInput.value.experience.slice(1)
//     // this.registerJson.neighborhood = this.formInput.value.neighborhood[0].toUpperCase() + this.formInput.value.neighborhood.slice(1)
//   }

//   //Calls to the back end

//   getDocumentTypes(){
//     this.api.get(this.pathDocumentType, this.tokenService.get())
//     .subscribe((res => {
//       this.documentTypes = res;
//     }))
//   }

//   getTestsGroup(){
//     this.token = this.tokenService.get();
//     this.api.get(this.pathGetTestGroup, this.token)
//     .subscribe((res => {
//       this.tests = res;
//       this.getTests()
//     }))
//   }

//   getTests(){
//    this.api.get(this.pathGetTests, this.tokenService.get()) 
//    .subscribe((res =>{
//     for (let resTests of res.data){
//       this.tests.push(resTests)
//     }
//    }))
//   }

//   getBusiness(){
//     this.api.get(this.pathGetBusiness, this.tokenService.get())
//     .subscribe((res => {
//       this.responseBusiness = res
//       this.responseBusiness = this.responseBusiness.sort((a, b) => a.name.localeCompare(b.name));
//     }))
//   }

//   getGender(){
//     this.api.get(this.pathGetGender, this.tokenService.get())
//     .subscribe((res => {
//       this.genders = res
//       this.genders = this.genders.sort((a, b) => a.name.localeCompare(b.name));
//     }))
//   }

//   getDepartaments(){
//     this.api.get(this.pathGetDepartament, this.tokenService.get())
//     .subscribe((res => {
//       this.departaments = res
//       this.departaments = this.departaments.sort((a, b) => a.name.localeCompare(b.name));
//     }))
//   }
//   getMunicipaly(id:string){
//     this.api.get(this.pathGetMunicipaly + '/' + id, this.tokenService.get())
//     .subscribe((res => {
//       this.municipalys = res;
//       this.municipalys = this.municipalys.sort((a, b) => a.name.localeCompare(b.name));
//       this.loadingController.dismiss()
//     }))
//   }

//   getDominantHands(){
//     this.api.get(this.pathDominantHand, this.tokenService.get())
//     .subscribe((res => {
//       this.dominantHands = res;
//       this.dominantHands = this.dominantHands.sort((a, b) => a.name.localeCompare(b.name));
//     }))
//   }

//   getAcademicLevel(){
//     this.api.get(this.pathAcademicLevel, this.tokenService.get())
//     .subscribe((res => {
//       this.academicLevels = res;
//     }))
//   }

//   reloadingData(){
//     this.auxReloading = 1
//     this.showLoading()
//     this.tokenService.validToken()
//     this.getDocumentTypes()
//     this.getGender()
//     this.getTestsGroup()
//     this.getBusiness()
//     this.getDepartaments()
//     this.getDominantHands()
//     this.getAcademicLevel()
//     this.loadData()
//     this.date = new Date().toISOString()
//     this.bodyPostSesionFilter.filterDate = this.date.substring(0,10)
//     this.getSesions()
//     this.enableSave = false 
//   }


//   createUser(){
//     // if (this.formInput.value.ohterBusiness != undefined){
//     //   this.formInput.value.uuidBusinessLineEntities = this.formInput.value.ohterBusiness
//     // }
//     if(this.formInput.value.dateBirth === null){
//       this.alreadyUserAlert(register.ADD_DATEBIRTH)
//     }else if (this.formInput.value.uuidUserDocumentType === null){
//       this.alreadyUserAlert(register.SELECT_DOCUMENT_TYPE)
//     } else if (this.formInput.value.uuidUserGender === null){
//       this.alreadyUserAlert(register.SELECT_USER_GENDER)
//     } else if (this.formInput.value.uuidGeoDepartament === null){
//       this.alreadyUserAlert(register.SELECT_GEO_DEPARTAMENT)
//     } else if (this.formInput.value.uuidGeoMunicipaly === null){
//       this.alreadyUserAlert(register.SELECT_GEO_MUNICIPALY)
//     } else if (this.formInput.value.uuidUserDominantHands === null){
//       this.alreadyUserAlert(register.SELECT_USER_DOMINANT_HAND)
//     } else if (this.formInput.value.uuidUserDominantHands === null){
//       this.alreadyUserAlert(register.SELECT_USER_DOMINANT_HAND)
//     } else if (this.formInput.value.uuidAcademicLevel === null){
//       this.alreadyUserAlert(register.SELECT_ACADEMIC_LEVEL)
//     } else if (this.formInput.value.uuidBusinessLineEntities === null){
//       this.alreadyUserAlert(register.SELECT_BUSINESS)
//     } else if (this.formInput.value.testToCarried === null){
//       this.alreadyUserAlert(register.SELECT_TESTS)
//     } else if (this.formInput.value.sesionId === null){
//       this.alreadyUserAlert(register.SELECT_SESION)
//     }else{
//       this.testGroupID = this.formInput.value.testToCarried
//       this.addData()
//       this.showLoadingTime()
//       this.enableSave = true
//       this.api.post(this.pathPostUser, this.registerJson, this.tokenService.get())
//       .subscribe((res =>{
//         this.userID = res.uuidUser;
//         if (res.message != undefined){
//           localStorage.removeItem('data');
//           this.createAttempt();
//         } else{ 
//           this.alreadyUserAlert(res)
//           this.enableSave = false
//           this.loadingController.dismiss()
//         }
//       }))
//     }
//   }
  
//   async showLoading() {
//     const loading = await this.loadingController.create({
//       message: appConstanst.loading,
//     });
//     loading.present();
//   }
//   async showLoadingTime() {
//     const loading = await this.loadingController.create({
//       message: appConstanst.loading,
//       duration : 5000
//     });
//     loading.present();
//   }

//   userSesion(){
//     let postUserSesion = {
//       sesionId : this.formInput.value.sesionId,
//       userId : this.userID
//     }
//     this.api.post(this.pathUserSesion, postUserSesion, this.tokenService.get())
//     .subscribe((res =>{
//       if (res === registerCandidate.userSesionAdd){
//         location.href = this.pageHome
//       }else{
//         this.loadingController.dismiss()
//         this.alreadyUserAlert(res)
//       }
//     }))
//   }

//   createAttempt(){
//      this.bodyPostAttempt = {
//       userId : this.userID,
//       testId : '',
//       testGroupId : '',
//       businessId : this.formInput.value.uuidBusinessLineEntities
//     }
//      this.api.post(this.pathPostAttempt, this.bodyPostAttempt, this.tokenService.get())
//      .subscribe((res => {
//        this.uuidAttempt = res.uuidAttempt
//        this.createAttempTest();
//      }))
//   }

//   createAttempTest(){
//     this.bodyPostAttemptTests = {
//       attemptId : this.uuidAttempt,
//       testId : this.formInput.value.testToCarried
//     }
//     this.api.post(this.pathPostAttemptTests, this.bodyPostAttemptTests, this.tokenService.get())
//     .subscribe((res =>{
//       if (register.saveData == res){
//         // location.href = this.pageHome
//         this.userSesion()
//       }
//     }))
//   }

//   redirectHabeasData(){
//     localStorage.setItem('data', JSON.stringify(this.formInput.value))
//     location.href = this.pathDataTreatmen
//   }

//   loadData(){
//     this.register = JSON.parse(localStorage.getItem('data'))
//     if (this.register != null){
//       this.formInput.setValue({
//         name : this.register.name,
//         email : this.register.email,
//         uuidUserDocumentType : this.register.uuidUserDocumentType,
//         phone : this.register.phone,
//         acceptHabeasData : this.register.acceptHabeasData,
//         uuidAcademicLevel : this.register.uuidAcademicLevel,
//         address : this.register.address,
//         uuidBusinessLineEntities : this.register.uuidBusinessLineEntities,
//         uuidGeoDepartament : this.register.uuidGeoDepartament,
//         dateBirth : this.register.dateBirth,
//         uuidUserDominantHands : this.register.uuidUserDominantHands,
//         experience : this.register.experience,
//         familyNucleus : this.register.familyNucleus,
//         uuidUserGender : this.register.uuidUserGender,
//         graduationYear : this.register.graduationYear,
//         graduationInstitution : this.register.graduationInstitution,
//         height : this.register.height,
//         weight : this.register.weight,
//         municipaly : this.register.municipaly,
//         uuidGeoMunicipaly : this.register.uuidGeoMunicipaly,
//         testToCarried : this.register.testToCarried,
//         neighborhood : this.register.neighborhood,
//         documentNumber : this.register.documentNumber,
//         sesionId : this.register.sesionId
//       })
//     }
//   }


//   //Button controls

//   buttonSave(){
//     const confirmEmail = {
//       confirmEmail : this.formInput.value.email,
//     }
//     this.registerJson = Object.assign(this.formInput.value, confirmEmail)
//   }

//   buttonReturn(){
//     location.href = this.pageHome
//   }

//   //Alerts

//   async confirmSaveDataAlert() {
//     const alert = await this.alertController.create({
//       header: 'Atención',
//       message : '¿Deseas guardar la información?',
//       cssClass: 'confirm-save-data-alert',
//       buttons: [
//         {
//           text: 'No',
//           cssClass: 'alert-button-no',
//         },
//         {
//           text: 'Guardar',
//           cssClass: 'alert-button-confirm',
//           handler : () => {
//             this.createUser()
//           }
//         },
//       ],
//     });
//     await alert.present();
//   }

//   async alreadyUserAlert(message) {
//     const alert = await this.alertController.create({
//       header: 'Atención',
//       message : message,
//       cssClass: 'confirm-save-data-alert',
//       buttons: [
//         {
//           text: 'OK',
//         },
//       ],
//     });
//     await alert.present();
//   }
// }


titleAddresContact = registerCandidate.titleAddresContact;
departament = registerCandidate.departament;
municipaly = registerCandidate.municipaly;
neighborhood = registerCandidate.neighborhood;
address = registerCandidate.Address;
phone = registerCandidate.phone;
emailPage = registerCandidate.email;
morphology = registerCandidate.titleMorphology;
height = registerCandidate.height;
weight = registerCandidate.weight;
dominantHand = registerCandidate.dominantHand;
left = registerCandidate.left;
right = registerCandidate.right
completeName = registerCandidate.completeName
documentNumber = registerCandidate.documentNumber
dateBirth = registerCandidate.dateBirth
documentType = registerCandidate.documentType
familyNucleus = registerCandidate.familyNucleus
numberChildren = registerCandidate.numberChildren
gender = registerCandidate.gender
dataTreatment = habeasData.titleCheckbox
seeDataTreatment:string = habeasData.see
titleInformationAcademic = registerCandidate.titleInformationAcademic
academicLevel = registerCandidate.academicLevel
graduationYear = registerCandidate.graduationYear
graduationInstitution = registerCandidate.graduationInstitution
titleWorkingInformation = registerCandidate.titleWorkingInformation
experience = registerCandidate.experience
companyApply = registerCandidate.companyApply
testsSesion = registerCandidate.testsSesion
placeholderSesion = registerCandidate.placeholderSesion
testToCarried = registerCandidate.testToCarried
save = registerCandidate.save
business = registerCandidate.business

titleInformationBasic = registerCandidate.titleInformationBasic
nameImage = registerCandidate.nameImageRegister
errorDocumentTypes = registerCandidate.errorDocumentTypes
errorGender = registerCandidate.errorGender
errorDepartament = registerCandidate.errorDepartament
errorMunicipaly = registerCandidate.errorMunicipaly
errorDominantHand = registerCandidate.errorDominantHand
errorAcademicLevel = registerCandidate.errorAcademicLevel
errorBusiness = registerCandidate.errorBusiness
errorSessions = registerCandidate.errorSessions
errorTestGroup = registerCandidate.errorTestGroup
errorTests = registerCandidate.errorTests
alreadyUser = registerCandidate.alreadyUser
emailAlready = registerCandidate.emailAlready
registerUserOk = registerCandidate.registerUserOk


goBack = registerCandidate.goBack;
next = registerCandidate.next

pageHome = '/auth/home'
goBackPage = '/auth/information-basic';
nextPage = '/auth/information-academic';
pathDocumentType:string = '/document_type';
pathGender:string = '/gender';
pathDepartament:string = '/departament'
pathMunicipalyDepartament:string = '/municipality/departament/'
pathDominantHands:string = '/dominant_hand'
pathAcademicLevel:string = '/academic_level'
pathBusiness:string = '/business'
pathSessions:string = '/sessions_filter'
pathTestGroup:string = '/test_group'
pathTests:string = '/tests'
pathCreatedCandidate:string = '/created/candidate'
data:any;
register:any;

dateSesion!:string;

enableSave = false

testGroupOnline = ['7dd277a9-3de3-48b0-a2ff-628de2dd0a23', 'd23ef4dd-71be-4505-ad14-b9a6d3e74c73']
testsOnline:any[] = ['365c9226-6f21-49d2-ae2a-8f52a544e6f2', '64c9e6eb-95b1-4b57-a794-f44d837a8b14', '51fe8807-1cf1-4016-8e59-f797f134e710', 'de94a969-f823-4d66-b1f1-36cc5e48ef9f', '678ebe91-c113-4d22-acc6-8d8b05173e3a']

documentTypes:any[] = []
genders:any[] = []
departaments:any[] = []
municipalys:any[] = []
dominantHands:any[] = []
academicLevels:any[] = []
responseBusiness:any[] = [];
sessions:any[] = []
tests:any[] = []
testAux:any[] = []

registerConst = registerCandidate.registerConst

alertTestToCarried = {
  header: registerCandidate.headerAlertTestToCarried,
  message: registerCandidate.messageAlertTestToCarried,
  translucent: true,
};

bodyPostSesionFilter = {
  filterDate : '',
}



//Forms
formInput = new FormGroup({
  name : new FormControl(),
  email : new FormControl(),
  uuidUserDocumentType : new FormControl(),
  documentNumber : new FormControl(),
  phone : new FormControl(),
  acceptHabeasData : new FormControl(),
  uuidAcademicLevel : new FormControl(),
  address : new FormControl(),
  uuidBusinessLineEntities : new FormControl(),
  uuidGeoDepartament : new FormControl(),
  dateBirth : new FormControl(),
  uuidUserDominantHands : new FormControl(),
  experience : new FormControl(),
  familyNucleus : new FormControl(),
  numberChildren : new FormControl(),
  uuidUserGender : new FormControl(),
  graduationYear : new FormControl(),
  graduationInstitution : new FormControl(),
  height : new FormControl(),
  weight : new FormControl(),
  uuidGeoMunicipaly : new FormControl(),
  testId : new FormControl(),
  neighborhood : new FormControl(),
  sesionId : new FormControl()
})

constructor(
  private api : ApiService,
  private token : TokenService,
  private loadingController : LoadingController,
  private alertController : AlertController,
  private barcodeScanner : BarcodeScanner,
) {
  const date = new Date();
  this.bodyPostSesionFilter.filterDate = date.toISOString().substring(0,10)
 }

ngOnInit() {
  setTimeout(() => {
    this.getDocumentTypes()
    this.getGender()
    this.getDepartament()
    this.getDominantHands()
    this.getAcademicLevel()
    this.getBusiness()
    this.getSession()
    this.getTestGroup()
  }, 0);
}

getDocumentTypes(){
  this.api.get(this.pathDocumentType, this.token.get())
  .subscribe(res =>{
    if(res !== this.errorDocumentTypes){
      this.documentTypes = res
    }
  })
}

getGender(){
  this.api.get(this.pathGender, this.token.get())
  .subscribe(res =>{
    if (res !== this.errorGender){
      this.genders = res
    }
  })
}

getDepartament(){
  this.api.get(this.pathDepartament, this.token.get())
  .subscribe(res =>{
    if (res !== this.errorDepartament){
      this.departaments = res
      this.departaments = this.departaments.sort((a, b) => a.name.localeCompare(b.name));
    }
  })
}

getMunicipaly(id:string){
  this.api.get(this.pathMunicipalyDepartament + id, this.token.get())
  .subscribe(res => {
    if (res !== this.errorMunicipaly){
      this.municipalys = res;
      this.municipalys = this.municipalys.sort((a, b) => a.name.localeCompare(b.name));
      // this.loadingController.dismiss()
    }
  })
}

getDominantHands(){
  this.api.get(this.pathDominantHands, this.token.get())
  .subscribe(res =>{
    if (res !== this.errorDominantHand){
      this.dominantHands = res
    }
  })
}

getAcademicLevel(){
  this.api.get(this.pathAcademicLevel, this.token.get())
  .subscribe(res =>{
    if (res !== this.errorAcademicLevel){
      this.academicLevels = res
    }
  })
}

getBusiness(){
  this.api.get(this.pathBusiness, this.token.get())
  .subscribe(res =>{
    if (res !== this.errorBusiness){
      this.responseBusiness = res
      this.responseBusiness = this.responseBusiness.sort((a, b) => a.name.localeCompare(b.name));
    }
  })
}

getSession(){
  this.api.post(this.pathSessions, this.bodyPostSesionFilter, this.token.get())
  .subscribe(res => {
    if (res !== this.errorSessions){
      this.sessions = res
    }
  })
}

getTestGroup(){
  this.api.get(this.pathTestGroup, this.token.get())
  .subscribe(res =>{
    if (res !== this.errorTestGroup){
      this.testAux = res
      this.tests = this.testAux.filter(objeto => !this.testGroupOnline.includes(objeto.uuid));
      this.tests = this.tests.sort((a, b) => a.name.localeCompare(b.name));
      this.getTests()
    }
  })
}

getTests(){
  this.api.get(this.pathTests, this.token.get())
  .subscribe(res =>{
    if (res !== this.errorTests){
      this.testAux = res.data
      this.testAux = this.testAux.filter(objeto => !this.testsOnline.includes(objeto.uuid));
      this.testAux = this.testAux.sort((a, b) => a.name.localeCompare(b.name));
      this.tests = [...this.tests, ...this.testAux]
    }
  })
}

redirectGoBack(){
  
}

redirectNext(){

}

redirectHabeasData(){

}

onChangeMunicipaly(){
  this.getMunicipaly(this.formInput.value.uuidGeoDepartament)
}

testGroupIds(){
  
}

buttonReturn(){
  location.href = this.pageHome
}


reloadingData(){
  this.getDocumentTypes()
  this.getGender()
  this.getDepartament()
  this.getDominantHands()
  this.getAcademicLevel()
  this.getBusiness()
  this.getSession()
  this.getTestGroup()
}

createUser(){
  if(this.formInput.value.dateBirth === null){
    this.alreadyUserAlert(registerCandidate.ADD_DATEBIRTH)
  }else if (this.formInput.value.uuidUserDocumentType === null){
    this.alreadyUserAlert(registerCandidate.SELECT_DOCUMENT_TYPE)
  } else if (this.formInput.value.uuidUserGender === null){
    this.alreadyUserAlert(registerCandidate.SELECT_USER_GENDER)
  } else if (this.formInput.value.uuidGeoDepartament === null){
    this.alreadyUserAlert(registerCandidate.SELECT_GEO_DEPARTAMENT)
  } else if (this.formInput.value.uuidGeoMunicipaly === null){
    this.alreadyUserAlert(registerCandidate.SELECT_GEO_MUNICIPALY)
  } else if (this.formInput.value.uuidUserDominantHands === null){
    this.alreadyUserAlert(registerCandidate.SELECT_USER_DOMINANT_HAND)
  } else if (this.formInput.value.uuidUserDominantHands === null){
    this.alreadyUserAlert(registerCandidate.SELECT_USER_DOMINANT_HAND)
  } else if (this.formInput.value.uuidAcademicLevel === null){
    this.alreadyUserAlert(registerCandidate.SELECT_ACADEMIC_LEVEL)
  } else if (this.formInput.value.uuidBusinessLineEntities === null){
    this.alreadyUserAlert(registerCandidate.SELECT_BUSINESS)
  } else if (this.formInput.value.testId === null){
    this.alreadyUserAlert(registerCandidate.SELECT_TESTS)
  } else if (this.formInput.value.sesionId === null){
    this.alreadyUserAlert(registerCandidate.SELECT_SESION)
  }else{
    this.api.post(this.pathCreatedCandidate, this.formInput.value, this.token.get())
    .subscribe(res =>{
      if (res === this.registerUserOk){
        location.reload()
      }else{
        this.alreadyUserAlert(res)
      }
    })
  }
}

//Functions
code : any;
scanner(){
  console.log('dd')
  this.barcodeScanner.scan({
    formats : 'PDF_417'
  }).then(barcodeData => {
    this.decodePDF417(barcodeData.text)
   }).catch(err => {
       console.log('Error', err);
   });
}

decodePDF417(data:any){
  let nameDecode, documentNumberDecode, dateBirthDecode; 
  nameDecode = data.substring(100,115).replace(/ /g, '').replace(/\u0000/g, '') + ' ' + data.substring(115, 135).replace(/ /g, '').replace(/\u0000/g, '') + ' ' + data.substring(58, 70).replace(/ /g, '').replace(/\u0000/g, '') + ' ' +  data.substring(70, 100).replace(/ /g, '').replace(/\u0000/g, '')
  nameDecode = nameDecode.toString()
  dateBirthDecode = data.substring(152,156) + '-' + data.substring(156,158) + '-' + data.substring(158,160)
  try{
    documentNumberDecode = parseInt(data.substring(48, 58)).toString()
  }
  catch{
    documentNumberDecode = data.substring(48,58)
  }
  this.formInput.setValue({
    name : nameDecode,
    email : null,
    uuidUserDocumentType : null,
    phone : null,
    acceptHabeasData : null,
    uuidAcademicLevel : null,
    address : null,
    uuidBusinessLineEntities : null,
    uuidGeoDepartament : null,
    dateBirth : dateBirthDecode,
    uuidUserDominantHands : null,
    experience : null,
    familyNucleus : null,
    numberChildren : null,
    uuidUserGender : null,
    graduationYear : null,
    graduationInstitution : null,
    height : null,
    weight : null,
    uuidGeoMunicipaly : null,
    testId : null,
    neighborhood : null,
    documentNumber : documentNumberDecode,
    sesionId : null
  })
}

//Alerts

async confirmSaveDataAlert() {
  const alert = await this.alertController.create({
    header: 'Atención',
    message : '¿Deseas guardar la información?',
    cssClass: 'confirm-save-data-alert',
    buttons: [
      {
        text: 'No',
        cssClass: 'alert-button-no',
      },
      {
        text: 'Guardar',
        cssClass: 'alert-button-confirm',
        handler : () => {
          this.createUser()
        }
      },
    ],
  });
  await alert.present();
}

async alreadyUserAlert(message:string) {
  const alert = await this.alertController.create({
    header: 'Atención',
    message : message,
    cssClass: 'confirm-save-data-alert',
    buttons: [
      {
        text: 'OK',
      },
    ],
  });
  await alert.present();
}

}
