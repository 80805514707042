import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-tabs',
  templateUrl: './menu-tabs.component.html',
  styleUrls: ['./menu-tabs.component.scss'],
})
export class MenuTabsComponent implements OnInit {

  constructor() { }

  nameHome = 'Inicio';
  nameTests = 'Pruebas';
  nameReports = 'Reportes';
  nameChangePassword = 'Cambiar la contraseña';
  @Input() decision:number;
  color1 = '';
  color2 = '';
  color3 = '';
  color4 = '';

  decisionSelected(decision){
    if (decision == 1){
      this.color1 = 'primary'
    }
    if (decision == 2){
      this.color2 = 'primary'
    }
    if (decision == 3){
      this.color3 = 'primary'
    }
    if (decision == 4){
      this.color4 = 'primary'
    }
  }

  ngOnInit() {
    this.decisionSelected(this.decision)
  }

}
