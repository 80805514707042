import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
// import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { habeasData, registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-form-register-candidate-information-basic',
  templateUrl: './form-register-candidate-information-basic.component.html',
  styleUrls: ['./form-register-candidate-information-basic.component.scss'],
})
export class FormRegisterCandidateInformationBasicComponent implements OnInit {

  constructor(
    // private barcodeScanner : BarcodeScanner
    private api : ApiService,
    private token : TokenService
  ) { }

  next = 'Siguiente';
  goBack = 'Regresar';

  titleInformationBasic = registerCandidate.titleInformationBasic;
  documentType = registerCandidate.documentType;
  identificationID = registerCandidate.identificationId;
  identityCard = registerCandidate.identityCard;
  specialResidencePermit = registerCandidate.specialResidencePermit;
  foreignerId = registerCandidate.foreignerId

  documentNumber = registerCandidate.documentNumber;
  completeName = registerCandidate.completeName;
  dateBirth = registerCandidate.dateBirth;
  gender = registerCandidate.gender;
  familyNucleus = registerCandidate.familyNucleus;
  titleRegister = registerCandidate.titleRegister;
  nameImage = registerCandidate.nameImageRegister;

  formInput = new FormGroup({
    documentType : new FormControl(),
    documentNumber : new FormControl(),
    name : new FormControl(),
    dateBirth : new FormControl(),
    gender : new FormControl(),
    familyNucleus : new FormControl(),
    acceptHabeasData : new FormControl(),
  })

  nextPage = '/auth/address-contact';
  returnPage = '/auth/home';
  register:any;
  dataTreatment:string = habeasData.titleCheckbox
  seeDataTreatment:string = habeasData.see
  pathDataTreatmen:string = 'auth/accept-habeas-data'
  pathDocumentType:string = '/document_type'
  documentTypes:any;


  redirectHabeasData(){
    localStorage.setItem('register', JSON.stringify(this.formInput.value))
    location.href = this.pathDataTreatmen;
  }

  buttonNext(){
    this.saveData();
    location.href = this.nextPage
  }

  buttonReturn(){
    localStorage.removeItem('register')
    location.href = this.returnPage
  }

  saveData(){
    localStorage.setItem('register', JSON.stringify(this.formInput.value))
  }

  setDataForm(){
    this.register = JSON.parse(localStorage.getItem('register'))
    if (this.register != null){
      this.formInput.setValue({
        documentType : this.register.documentType,
        documentNumber : this.register.documentNumber,
        name : this.register.name,
        dateBirth : this.register.dateBirth,
        gender : this.register.gender,
        familyNucleus : this.register.familyNucleus,
        acceptHabeasData : this.register.acceptHabeasData
      })
    }
  }

  getDocumentTypes(){
    this.api.get(this.pathDocumentType, this.token.get())
    .subscribe((res => {
      this.documentTypes = res;
    }))
  }

  code:any;

  scanne(){
    // this.barcodeScanner.scan().then(barcodeData => {
    //   this.code = barcodeData.text
    //   console.log('Barcode data', this.code);
    //  }).catch(err => {
    //      console.log('Error', err);
    //  });
  }

  ngOnInit() {
    this.token.validToken()
    this.setDataForm();
    this.getDocumentTypes();
  }
}
