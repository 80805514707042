import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { appConstanst } from '../../constant/app-constans';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent implements OnInit {

  @Input() placeholder = appConstanst.date
  @Input() formInput : FormGroup;
  @Input() label : string;

  constructor() { }

  ngOnInit() {}

}
