export const register = {
  ALREADY_USER : 'El usuario ya se encuentra registrado',
  ALREADY_EMAIL : 'El correo electrónico ya se encuentra registrado',
  ADD_USER : 'Usuario agregado',
  ADD_DATEBIRTH : 'Ingrese la fecha de nacimiento',
  saveData : 'Datos guardados',
  registerCandidate : 'Registro de candidatos',
  SELECT_DOCUMENT_TYPE : 'Seleccione un tipo de documento',
  SELECT_USER_GENDER : 'Seleccione un genero',
  SELECT_GEO_DEPARTAMENT : 'Seleccione un departamento',
  SELECT_GEO_MUNICIPALY : 'Seleccione un municipio',
  SELECT_USER_DOMINANT_HAND : 'Seleccione la mano dominante',
  SELECT_ACADEMIC_LEVEL : 'Seleccione el nivel académico',
  SELECT_BUSINESS : 'Seleccione la empresa',
  SELECT_TESTS : 'Seleccione una o varias pruebas',
  SELECT_SESION : 'Seleccione una sesión'
  
}