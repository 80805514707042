import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdjustableHeightService {

  constructor() { }

  heightComponent:any;
  width:any;

  adjustableHeightTabs(subtraction:number){
    this.heightComponent = (screen.height - subtraction).toString() + 'px'
    document.documentElement.style.setProperty('--screen-height', this.heightComponent)
  }

  adjustableWidth(width:number){
    this.width = (width).toString() + 'px'
    document.documentElement.style.setProperty('--screen-width', this.width)
  }

}
