import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-test-questions',
  templateUrl: './feature-test-questions.component.html',
  styleUrls: ['./feature-test-questions.component.scss'],
})
export class FeatureTestQuestionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
