import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { register } from '../../constants/contatns';
import { registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-form-register-candidate-information-academic',
  templateUrl: './form-register-candidate-information-academic.component.html',
  styleUrls: ['./form-register-candidate-information-academic.component.scss'],
})
export class FormRegisterCandidateInformationAcademicComponent implements OnInit {
  
  constructor(
    private alertController : AlertController,
    private apiService : ApiService,
    private tokenService : TokenService,
  ) { }

  titleInformationAcademic = registerCandidate.titleInformationAcademic;
  academicLevel = registerCandidate.academicLevel;
  graduationYear = registerCandidate.graduationYear;
  titleWorkingInformation = registerCandidate.titleWorkingInformation;
  experience = registerCandidate.experience;
  companyApply = registerCandidate.companyApply;
  business = registerCandidate.business;
  other = registerCandidate.otherBusiness;
  testToCarried = registerCandidate.testToCarried;

  goBack = registerCandidate.previous;
  save = registerCandidate.save;
  register:any;
  year = new Date().toLocaleDateString('es', {year:"numeric"}).toString();
  userID:any = '';
  testGroupID:any = '';

  // Bodys JSON
  bodyPostAttempt:any;
  bodyPostAttemptTests:any;

  aditionalData:any = {
    password : null,
    trash : null,
    enable : null,
    lastName : null,
    profileId : registerCandidate.profileId
  }

  uuidUser:any;
  uuidAttempt:any;
  token:string;
  pathGetTestGroup:string = '/test_group'
  pathGetTests:string = '/tests'
  tests:any;
  pathPostUser:string = '/user'
  pathPostAttempt = '/test_attempt'
  pathPostAttemptTests = '/attempt_tests'
  pathGetBusiness:string = '/business';
  responseBusiness:any;

  goBackPage = '/auth/address-contact';
  validBusiness = registerCandidate.validBusiness;
  homePage = '/auth/home'

  alertTestToCarried = {
    header: registerCandidate.headerAlertTestToCarried,
    message: registerCandidate.messageAlertTestToCarried,
    translucent: true,
  };

  formInput = new FormGroup({
    academicLevel : new FormControl(),
    graduationYear : new FormControl(),
    experience : new FormControl(),
    business : new FormControl(),
    ohterBusiness : new FormControl(),
    testToCarried : new FormControl(),
  })

  redirectPrevious(){
    location.href = this.goBackPage
  }

  disableFormInput(){
    this.formInput.controls['ohterBusiness'].disable();
  }

  onChangeInputOther(){
    if (this.formInput.value.business == registerCandidate.validBusiness){
      this.formInput.controls['ohterBusiness'].enable();
    } else{
      this.formInput.controls['ohterBusiness'].disable();
    }
  }

  testGroupIds(){
    this.testGroupID = this.formInput.value.testToCarried
  }

  printData(){
    console.log(this.formInput.value.testToCarried)
  }

  saveData(){
    if (this.formInput.value.ohterBusiness != undefined){
      this.formInput.value.business = this.formInput.value.ohterBusiness
    }
    this.register = Object.assign(JSON.parse(localStorage.getItem('register')), this.formInput.value, this.aditionalData)
    this.testGroupID = this.formInput.value.testToCarried
    this.sendDataUser();   
  }

  sendDataUser(){
    this.apiService.post(this.pathPostUser, this.register, this.tokenService.get())
    .subscribe((res =>{
      console.log(res)
      this.userID = res.uuidUser;
      if (register.ALREADY_USER == res){
        this.alreadyUserAlert(register.ALREADY_EMAIL)
      }else{
        this.createAttempt();
      }
    }))
  }

  createAttempt(){
     this.bodyPostAttempt = {
      userId : this.userID,
      testId : '',
      testGroupId : '',
    }
     this.apiService.post(this.pathPostAttempt, this.bodyPostAttempt, this.tokenService.get())
     .subscribe((res => {
       this.uuidAttempt = res.uuidAttempt
       this.createAttempTest();
     }))
  }

  createAttempTest(){
    this.bodyPostAttemptTests = {
      attemptId : this.uuidAttempt,
      testId : this.formInput.value.testToCarried
    }
    this.apiService.post(this.pathPostAttemptTests, this.bodyPostAttemptTests, this.tokenService.get())
    .subscribe((res =>{
      if (register.saveData == res){
        location.href = this.homePage
        this.clearLocalStorage()
      }
    }))
  }

  clearLocalStorage(){
    localStorage.removeItem('register')
  }

  async alreadyUserAlert(message) {
    const alert = await this.alertController.create({
      header: 'Atención',
      message : message,
      cssClass: 'confirm-save-data-alert',
      buttons: [
        {
          text: 'Guardar',
        },
      ],
    });

    await alert.present();
  }



  async confirmSaveDataAlert() {
    const alert = await this.alertController.create({
      header: 'Atención',
      message : '¿Deseas guardar la información?',
      cssClass: 'confirm-save-data-alert',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-no',
        },
        {
          text: 'Guardar',
          cssClass: 'alert-button-confirm',
          handler : () => {
            this.saveData()
          }
        },
      ],
    });

    await alert.present();
  }

  

  getTestsGroup(){
    this.token = this.tokenService.get();
    this.apiService.get(this.pathGetTestGroup, this.token)
    .subscribe((res => {
      this.tests = res;
      this.getTests()
    }))
  }

  getTests(){
   this.apiService.get(this.pathGetTests, this.tokenService.get()) 
   .subscribe((res =>{
    for (let resTests of res.data){
      this.tests.push(resTests)
    }
   }))
  }

  getBusiness(){
    this.apiService.get(this.pathGetBusiness, this.tokenService.get())
    .subscribe((res => {
      this.responseBusiness = res
    }))
  }

  ngOnInit() {
    this.tokenService.validToken()
    this.disableFormInput()
    this.getTestsGroup();
    this.getBusiness();
  }

}
