import { Component, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { register } from '../../constants/contatns';

@Component({
  selector: 'app-auth-template-register-candidate',
  templateUrl: './auth-template-register-candidate.component.html',
  styleUrls: ['./auth-template-register-candidate.component.scss'],
})
export class AuthTemplateRegisterCandidateComponent implements OnInit {

  nameIcon:string = ''

  constructor(
    private loadingController : LoadingController,
  ) { }

  ngOnInit() {
    this.setSizeScreen()
    // this.showLoading()
  }

  //Variables
  titleHeader:string = register.registerCandidate

  setSizeScreen(){
    const sizeScreen = screen.height - 66
    document.documentElement.style.setProperty('--screen-height', sizeScreen + 'px')
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: 'Dismissing after 3 seconds...',
      duration : 1000,
      backdropDismiss : true
    });
    loading.present();
  }

}
