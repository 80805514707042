import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ButtonCardComponent } from '../app-common/atoms/button-card/button-card.component';
import { CardsComponent } from '../app-common/atoms/cards/cards.component';
import { FormInputTextComponent } from '../app-common/molecules/form-input-text/form-input-text.component';
import { FormTestQuestionsComponent } from './domain/organisms/form-test-questions/form-test-questions.component';

import { AuthPage } from './auth.page';
import { AuthTemplateLoginComponent } from './domain/templates/auth-template-login/auth-template-login.component';
import { FeatureAcceptHabeasDataComponent } from './feature-accept-habeas-data/feature-accept-habeas-data.component';
import { FeatureCandidatesComponent } from './feature-candidates/feature-candidates.component';
import { FeatureChangePasswordComponent } from './feature-change-password/feature-change-password.component';
import { FeatureHomeComponent } from './feature-home/feature-home.component';
import { FeatureLoginComponent } from './feature-login/feature-login.component';
import { FeatureRecoveryComponent } from './feature-recovery/feature-recovery.component';
import { FeatureRegisterCandidateAddressContactComponent } from './feature-register-candidate-address-contact/feature-register-candidate-address-contact.component';
import { FeatureRegisterCandidateInformationAcademicComponent } from './feature-register-candidate-information-academic/feature-register-candidate-information-academic.component';
import { FeatureRegisterCandidateInformationBasicComponent } from './feature-register-candidate-information-basic/feature-register-candidate-information-basic.component';
import { FeatureTestQuestionsComponent } from './feature-test-questions/feature-test-questions.component';
import { FeatureTestsComponent } from './feature-tests/feature-tests.component';
import { FeatureRegisterCandidateComponent } from './feature-register-candidate/feature-register-candidate.component';
import { FeatureReportComponent } from './feature-report/feature-report.component';
import { FeatureSesionComponent } from './feature-sesion/feature-sesion.component';
import { FeatureTestsCandidatesComponent } from './feature-tests-candidates/feature-tests-candidates.component';

const routes: Routes = [
  {
    path: 'auth',
    children : [
      
      {
        path : 'login',
        component: FeatureLoginComponent
      },
      {
        path : 'attempt',
        component : FormTestQuestionsComponent
      },
      {
        path : 'recovery',
        component : FeatureRecoveryComponent
      },
      {
        path : 'home',
        component : FeatureHomeComponent
      },
      {
        path : 'change-password',
        component : FeatureChangePasswordComponent
      },
      {
        path : 'candidates',
        component : FeatureCandidatesComponent
      },
      {
        path : 'register',
        component : FeatureRegisterCandidateComponent
      },
      {
        path : 'report',
        component : FeatureReportComponent
      },
      {
        path : 'sesions',
        component : FeatureSesionComponent
      },
      // {
      //   path : 'information-basic',
      //   component : FeatureRegisterCandidateInformationBasicComponent
      // },
      // {
      //   path : 'address-contact',
      //   component : FeatureRegisterCandidateAddressContactComponent
      // },
      // {
      //   path : 'information-academic',
      //   component : FeatureRegisterCandidateInformationAcademicComponent
      // },
      {
        path : 'accept-habeas-data',
        component : FeatureAcceptHabeasDataComponent
      },
      {
        path : 'tests/:uuid',
        component : FeatureTestsComponent
      },
      {
        path : 'test-questions/:userId/:attemptId',
        component : FeatureTestQuestionsComponent
      },
      {
        path : 'testMolecule',
        component : CardsComponent
      },
      {
        path : 'tests-candidates',
        component : FeatureTestsCandidatesComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
