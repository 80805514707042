import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-candidates',
  templateUrl: './feature-candidates.component.html',
  styleUrls: ['./feature-candidates.component.scss'],
})
export class FeatureCandidatesComponent implements OnInit {

  constructor() { }

  dateNow = new Date().toLocaleDateString('es', {year:"numeric", month:"long", day:"numeric"});
  ngOnInit() {}

}
