import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { appConstanst } from '../../constant/app-constans';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent implements OnInit {

  placeholder = appConstanst.search
  @Input() formInput : FormGroup;
  @Input() label : string;

  constructor() { }

  ngOnInit() {}

}
