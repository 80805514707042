import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-placeholder',
  templateUrl: './input-placeholder.component.html',
  styleUrls: ['./input-placeholder.component.scss'],
})
export class InputPlaceholderComponent implements OnInit {

  constructor() { }

  @Input() placeholder: string;
  @Input() formInput : FormGroup;
  @Input() type : string = '';
  @Input() label : string;
  @Input() maxlength : string;
  ngOnInit() {}

}
