import { Component, OnInit } from '@angular/core';
import { registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-auth-template-register-candidate-information-academic',
  templateUrl: './auth-template-register-candidate-information-academic.component.html',
  styleUrls: ['./auth-template-register-candidate-information-academic.component.scss'],
})
export class AuthTemplateRegisterCandidateInformationAcademicComponent implements OnInit {

  titleHeader = registerCandidate.titleRegister
  nameIcon = registerCandidate.nameIcon

  constructor() { }

  ngOnInit() {}

}
