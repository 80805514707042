import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private apiService : ApiService
  ) { }

  token:string;

  set(token:string){
    localStorage.setItem('token', token);
  }

  get(){
    return localStorage.getItem('token');
  }

  validToken(){
    this.apiService.get('/verification_token', localStorage.getItem('token'))
    .subscribe((res => {
      if (res != 'Token valido'){
        location.href = '/auth/login'
      }
    }))
  }

}
