import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-register-candidate-information-academic',
  templateUrl: './feature-register-candidate-information-academic.component.html',
  styleUrls: ['./feature-register-candidate-information-academic.component.scss'],
})
export class FeatureRegisterCandidateInformationAcademicComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
