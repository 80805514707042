import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss'],
})
export class ButtonCardComponent implements OnInit {

  @Input() iconStart = '';
  @Input() title = '';
  @Input() caption = '';
  @Input() redirect = '';

  iconEnd = '';  

  constructor() { }

  redirectLink(){
    location.href = this.redirect;
  }

  ngOnInit() {
    this.iconEnd = 'caret-forward-outline';
  }

}
