import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-template-test-questions',
  templateUrl: './auth-template-test-questions.component.html',
  styleUrls: ['./auth-template-test-questions.component.scss'],
})
export class AuthTemplateTestQuestionsComponent implements OnInit {

  constructor() { }

  titleHeader = 'Pruebas individuales'

  ngOnInit() {}

}
