import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss'],
})
export class FormInputTextComponent implements OnInit {

  @Input() text;
  @Input() position;
  @Input() color;

  @Input() type;

  public valueInput = new FormControl('');
  
  constructor() { }
  
  ngOnInit() {}
  
}
