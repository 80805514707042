import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { appConstanst } from 'src/app/app-common/constant/app-constans';
import { AdjustableHeightService } from 'src/app/app-common/services/adjustableHeight/adjustable-height.service';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';

@Component({
  selector: 'app-auth-template-sesions',
  templateUrl: './auth-template-sesions.component.html',
  styleUrls: ['./auth-template-sesions.component.scss'],
})
export class AuthTemplateSesionsComponent implements OnInit {

  constructor(
    private api : ApiService,
    private token : TokenService,
    private adjust : AdjustableHeightService,
    private activateRoute : ActivatedRoute,
    private loadingController : LoadingController,
  ) { }

  ngOnInit() {
    // this.showLoading()
    this.token.validToken()
    this.idSesion = this.activateRoute.snapshot.paramMap.get('sesionId')
    this.date = new Date().toISOString()
    this.bodyPostSesionFilter.filterDate = this.date.substring(0,10)
    this.getSesions()
    this.adjust.adjustableHeightTabs(156+63)
  }

  //Variables
  title = appConstanst.titelSesion
  date : any;
  sesions : any;
  selectSesion = appConstanst.selectSesion;
  bodySesion : any;
  idSesions : any = [];
  idSesion : any = [];
  loaded = true;
  
  //Variables path

  pathFilterSesions = '/sessions_filter'
  pathFilterCandidate = '/auth/candidates'
  //Json

  bodyPostSesionFilter = {
    filterDate : '',
  }

  //Function
  saveSesion(id){
    this.bodySesion = this.sesions[id]
    localStorage.setItem('sesion', JSON.stringify(this.bodySesion))
    location.href = this.pathFilterCandidate
  }

  validSesionDay(){
    let sesionSelected = JSON.parse(localStorage.getItem('sesion'))
    if (sesionSelected !== null){
      if(this.idSesions.includes(sesionSelected.uuid)){
        location.href = this.pathFilterCandidate// + sesionSelected.uuid
      }
    }else{
      console.log('nooo')
    }

  }

  //Requests to the backend
  getSesions(){
    this.api.post(this.pathFilterSesions, this.bodyPostSesionFilter, this.token.get())
    .subscribe((res =>{
      if (res !== appConstanst.not_exist_sesions){

        this.sesions = res
        this.idSesions = []
        for (let i of res){
          this.idSesions.push(i.uuid)
        }
        this.validSesionDay()
        this.loaded = false
        console.log(this.idSesions)
      }else{
        this.sesions = []
      }
      // this.loadingController.dismiss()
    }))
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
      duration : 1500
    });
    loading.present();
  }

}
