import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AuthRoutingModule } from './auth-routing.module';

import { AuthPage } from './auth.page';

import { AppCommonModule } from '../app-common/app-common.module';
import { FeatureLoginComponent } from './feature-login/feature-login.component';
import { AuthTemplateLoginComponent } from './domain/templates/auth-template-login/auth-template-login.component';
import { FormLoginComponent } from './domain/organisms/form-login/form-login.component';
import { FormTestQuestionsComponent } from './domain/organisms/form-test-questions/form-test-questions.component';
import { FeatureRecoveryComponent } from './feature-recovery/feature-recovery.component';
import { InputComponent } from '../app-common/atoms/input/input.component';
import { ButtonCardComponent } from '../app-common/atoms/button-card/button-card.component';
import { FeatureHomeComponent } from './feature-home/feature-home.component';
import { AuthTemplateRecoveryComponent } from './domain/templates/auth-template-recovery/auth-template-recovery.component';
import { FormRecoveryPasswordComponent } from './domain/organisms/form-recovery-password/form-recovery-password.component';
import { AuthTemplateHomeComponent } from './domain/templates/auth-template-home/auth-template-home.component';
import { FormChangePasswordComponent } from './domain/organisms/form-change-password/form-change-password.component';
import { FeatureCandidatesComponent } from './feature-candidates/feature-candidates.component';
import { FeatureChangePasswordComponent } from './feature-change-password/feature-change-password.component';
import { AuthTemplateChangePasswordComponent } from './domain/templates/auth-template-change-password/auth-template-change-password.component';
import { MenuComponent } from '../nav/templates/menu/menu.component';
import { MenuTabsComponent } from '../nav/templates/menu-tabs/menu-tabs.component';
import { AuthTemplateCandidatesComponent } from './domain/templates/auth-template-candidates/auth-template-candidates.component';
import { CardsComponent } from '../app-common/atoms/cards/cards.component';
import { FeatureRegisterCandidateInformationBasicComponent } from './feature-register-candidate-information-basic/feature-register-candidate-information-basic.component';
import { FeatureRegisterCandidateAddressContactComponent } from './feature-register-candidate-address-contact/feature-register-candidate-address-contact.component';
import { FeatureRegisterCandidateInformationAcademicComponent } from './feature-register-candidate-information-academic/feature-register-candidate-information-academic.component';
import { AuthTemplateRegisterCandidateInformationBasicComponent } from './domain/templates/auth-template-register-candidate-information-basic/auth-template-register-candidate-information-basic.component';
import { AuthTemplateRegisterCandidateAddressContactComponent } from './domain/templates/auth-template-register-candidate-address-contact/auth-template-register-candidate-address-contact.component';
import { AuthTemplateRegisterCandidateInformationAcademicComponent } from './domain/templates/auth-template-register-candidate-information-academic/auth-template-register-candidate-information-academic.component';
import { FormRegisterCandidateInformationBasicComponent } from './domain/organisms/form-register-candidate-information-basic/form-register-candidate-information-basic.component';
import { FormRegisterCandidateAddressContactComponent } from './domain/organisms/form-register-candidate-address-contact/form-register-candidate-address-contact.component';
import { FormRegisterCandidateInformationAcademicComponent } from './domain/organisms/form-register-candidate-information-academic/form-register-candidate-information-academic.component';
import { AuthTemplateAcceptHabeasDataComponent } from './domain/templates/auth-template-accept-habeas-data/auth-template-accept-habeas-data.component';
import { FeatureAcceptHabeasDataComponent } from './feature-accept-habeas-data/feature-accept-habeas-data.component';
import { MenuHeaderComponent } from '../nav/templates/menu-header/menu-header.component';
import { FeatureTestsComponent } from './feature-tests/feature-tests.component';
import { FormTestsCandidateComponent } from './domain/organisms/form-tests-candidate/form-tests-candidate.component';
import { AuthTemplateTestsCandidateComponent } from './domain/templates/auth-template-tests-candidate/auth-template-tests-candidate.component';
import { FeatureTestQuestionsComponent } from './feature-test-questions/feature-test-questions.component';
import { AuthTemplateTestQuestionsComponent } from './domain/templates/auth-template-test-questions/auth-template-test-questions.component';
import { AuthTemplateRegisterCandidateComponent } from './domain/templates/auth-template-register-candidate/auth-template-register-candidate.component';
import { FormRegisterCandidateComponent } from './domain/organisms/form-register-candidate/form-register-candidate.component';
import { FeatureRegisterCandidateComponent } from './feature-register-candidate/feature-register-candidate.component';
import { AuthTemplateReportComponent } from './domain/templates/auth-template-report/auth-template-report.component';
import { FormReportComponent } from './domain/organisms/form-report/form-report.component';
import { FeatureReportComponent } from './feature-report/feature-report.component';
import { AuthTemplateSesionsComponent } from './domain/templates/auth-template-sesions/auth-template-sesions.component';
import { FeatureSesionComponent } from './feature-sesion/feature-sesion.component';
import { FeatureTestsCandidatesComponent } from './feature-tests-candidates/feature-tests-candidates.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // IonicModule,
    AuthRoutingModule,
    AppCommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule.forRoot(),
  ],
  declarations: [
    AuthPage,
    AuthTemplateLoginComponent,
    AuthTemplateRecoveryComponent,
    AuthTemplateHomeComponent,
    AuthTemplateChangePasswordComponent,
    AuthTemplateCandidatesComponent,
    AuthTemplateAcceptHabeasDataComponent,
    AuthTemplateRegisterCandidateInformationBasicComponent,
    AuthTemplateRegisterCandidateInformationAcademicComponent,
    AuthTemplateRegisterCandidateAddressContactComponent,
    AuthTemplateTestsCandidateComponent,
    AuthTemplateTestQuestionsComponent,
    AuthTemplateRegisterCandidateComponent,
    AuthTemplateReportComponent,
    AuthTemplateSesionsComponent,
    
    FormChangePasswordComponent,
    FormRecoveryPasswordComponent,
    FormLoginComponent,
    FormTestQuestionsComponent,
    FormRegisterCandidateInformationBasicComponent,
    FormRegisterCandidateAddressContactComponent,
    FormRegisterCandidateInformationAcademicComponent,
    FormTestsCandidateComponent,
    FormTestQuestionsComponent,
    FormRegisterCandidateComponent,
    FormReportComponent,
    
    FeatureCandidatesComponent,
    FeatureLoginComponent,
    FeatureRecoveryComponent,
    FeatureHomeComponent,
    FeatureChangePasswordComponent,
    FeatureRegisterCandidateInformationBasicComponent,
    FeatureRegisterCandidateInformationAcademicComponent,
    FeatureRegisterCandidateAddressContactComponent,
    FeatureAcceptHabeasDataComponent,
    FeatureTestsComponent,
    FeatureTestQuestionsComponent,
    FeatureRegisterCandidateComponent,
    FeatureReportComponent,
    FeatureSesionComponent,
    FeatureTestsCandidatesComponent,

    MenuHeaderComponent,
    MenuComponent,
    MenuTabsComponent,
  ]
})

export class AuthModule {}
