import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { AdjustableHeightService } from 'src/app/app-common/services/adjustableHeight/adjustable-height.service';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { registerCandidate, testQuestionsType } from '../../constants/login-constants';
import { appConstanst } from 'src/app/app-common/constant/app-constans';

@Component({
  selector: 'app-form-test-questions',
  templateUrl: './form-test-questions.component.html',
  styleUrls: ['./form-test-questions.component.scss'],
})
export class FormTestQuestionsComponent implements OnInit {
  
  constructor(
    private activateRoute : ActivatedRoute,
    private alertController : AlertController,
    private api : ApiService,
    private tokenService : TokenService,
    private adjustable : AdjustableHeightService,
    private loadingController : LoadingController,
  ) { }

  testQuestions;
  titleTest:string;
  nameUser:string;
  buttonSave = registerCandidate.save
  buttonPrevius = registerCandidate.previous

  userId:string;
  attemptId:string;
  pathPostTestAttemptResponse:any = '/test/attempt/';
  pathPostTestAttemptResponseFinish:any = '/receipt_form';
  response:any = [];
  options : any = [];
  temp : any;
  aux = ['1','2','3']

  getTestQuestions(){
    let presentUser;
    let iPresent
    this.testQuestions = JSON.parse(localStorage.getItem('questions'))
    this.titleTest = this.testQuestions.name
    this.nameUser = this.testQuestions.nameUser
    this.temp = JSON.parse(localStorage.getItem('temp'))
    console.log(this.testQuestions)
    if (this.temp != null){
      for (let i = 0; i < this.temp.length; i++){
        if ((this.temp[i].userId === this.userId) && (this.temp[i].uuid == this.testQuestions.uuid)){
          presentUser = true;
          iPresent = i;
        }
      }
    }else{
      console.log('Igual')
    }
    if (presentUser == true){
      console.log('asd')
      this.testQuestions = this.temp[iPresent]
    }else{
      this.testQuestions = JSON.parse(localStorage.getItem('questions'))
      this.titleTest = this.testQuestions.name
      this.addValue()
      for (let i = 0; i < this.testQuestions.questions.length; i++){
        if (this.testQuestions.questions[i].questionType.name == testQuestionsType.InputFormNumber){
          this.testQuestions.questions[i].questionType.name = testQuestionsType.number
        }
      }
    }
  }

  responseData(){
    for (let i = 0; i < this.testQuestions.questions.length; i++){
      const aux = {
        userId : this.userId,
        attemptId : this.attemptId,
        questionId : this.testQuestions.questions[i].uuid,
        value : this.testQuestions.questions[i].value  ,
        timeResponse : null,
        questionOptionId : '',
      }
      this.response.push(aux)
    }
    console.log(this.response)
    this.showLoading()
    this.sendResponse();
    localStorage.removeItem('questions')
  }

  sendResponse(){
    let iPresent;
    let present;
    this.api.post(this.pathPostTestAttemptResponse + this.attemptId + this.pathPostTestAttemptResponseFinish, this.response, this.tokenService.get())
    .subscribe((res =>{
      if ('Datos agregados' == res){
        for (let i = 0; i < this.temp.length; i++){
          if (this.temp[i].userId === this.userId){
            iPresent = i;
            present = true
          }
        }
        console.log(iPresent)
        if (present == true){
          this.temp.splice(iPresent, 1)
          localStorage.setItem('temp', JSON.stringify(this.temp))
        }
        this.loadingController.dismiss()
        location.href = '/auth/tests/' + this.attemptId
      }
    }))
  }

  addValue(){
    for (let i = 0; i < this.testQuestions.questions.length; i++){
      this.testQuestions.questions[i] = Object.assign(this.testQuestions.questions[i], {value : null})
    }
  }

  async confirmSaveDataAlert() {
    const alert = await this.alertController.create({
      header: 'Atención',
      message : '¿Deseas guardar la información?',
      cssClass: 'confirm-save-data-alert',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-no',
        },
        {
          text: 'Guardar',
          cssClass: 'alert-button-confirm',
          handler : () => {
            this.responseData()
          }
        },
      ],
    });

    await alert.present();
  }

  getOptions(){
    this.options = this.testQuestions.questions
  }


  redirectPrevious(){
    let presentUser;
    if (this.temp !== null){
      for (let resTemp of this.temp){
        if (resTemp.userId === this.userId){
          presentUser = true;
        }
      }
      if(presentUser == true){
        this.testQuestions = Object.assign(this.testQuestions, {userId : this.userId})
        for (let i = 0; i < this.temp.length; i++){
          if (this.temp[i].userId === this.userId){
            this.temp[i] = this.testQuestions;
          }
        }
        console.log(this.temp)
        localStorage.setItem('temp', JSON.stringify(this.temp))
      }else{
        this.testQuestions = Object.assign(this.testQuestions, {userId : this.userId})
        this.temp.push(this.testQuestions)
        localStorage.setItem('temp', JSON.stringify(this.temp))  
      } 
    }else{
      this.temp = [];
      this.testQuestions = Object.assign(this.testQuestions, {userId : this.userId})
      this.temp.push(this.testQuestions)
      localStorage.setItem('temp', JSON.stringify(this.temp))
    }
    location.href = 'auth/tests/' + this.activateRoute.snapshot.paramMap.get('attemptId')
  }

  ngOnInit() {
    this.tokenService.validToken()
    this.adjustable.adjustableWidth(screen.width-35)
    this.userId = this.activateRoute.snapshot.paramMap.get('userId')
    this.attemptId = this.activateRoute.snapshot.paramMap.get('attemptId')
    this.getTestQuestions()  
    this.getOptions()
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
      duration : 3000,
    });
    loading.present();
  }

}
