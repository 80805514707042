import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit {
  
  constructor() { }
  
  @Input() nameCandidate = 'Jhoan';
  @Input() redirectLink = '/auth/home';
  @Input() nameBusiness = 'Causa y efecto';
  @Input() number = '1'
  @Input() select:boolean = false;
  @Input() documentNumber = ''
  bool:boolean = false;
  redirect(){
    location.href = this.redirectLink;
  }
  ngOnInit() {
  }

}
