export const appConstanst = {
    logoUrl : '/assets/logoNegro.png',
    api : 'https://api.causatalento.com',
    // api: 'https://api.qa.causatalento.com',
    // api : 'http://172.1.0.51:5000',
    // api : 'http://172.31.199.242:5000',
    titleDominantHand : 'Mano dominante:',
    loading : 'Cargando...',
    selectedOtherUser : 'Seleccione otro candidato',
    confirmCandidateSelected : 'Una vez los candidatos han sido seleccionados estarán bloqueado para los demás evaluadores.',
    continueUserSelected: '¿Desea continuar?',
    search : 'Buscar',
    business : 'Empresa',
    initialDate : 'Fecha inicial',
    finalDate : 'Fecha final',
    date : 'Fecha',
    generateReport : 'Generar reporte',
    userBlocked : 'Los siguientes candidatos ya han sido seleccionados y se encuentran bloqueados:',
    titelSesion : 'Sesiones',
    not_exist_sesions : 'No existen datos',
    not_exist_candidates : 'No existen candidatos',
    selectSesion : 'Seleccione una sesión'
}