import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-report',
  templateUrl: './feature-report.component.html',
  styleUrls: ['./feature-report.component.scss'],
})
export class FeatureReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
