import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputComponent } from 'src/app/app-common/atoms/input/input.component';
import { appConstanst } from 'src/app/app-common/constant/app-constans';

@Component({
  selector: 'app-auth-template-login',
  templateUrl: './auth-template-login.component.html',
  styleUrls: ['./auth-template-login.component.scss'],
})
export class AuthTemplateLoginComponent implements OnInit {

  @Input() testQuestions;
  src = '';
  loginTitle = '';
  caption = '';
  holaP = 'aa';
  @ViewChild(InputComponent) inputComponent;

  // ngAfterViewInit(){
  //   this.holaP = this.inputComponent.hola
  //   console.log(this.holaP)
  // }

  constructor() { }
  // public password = new FormControl('');

  // gourpForm = new FormGroup({
  //   email : new FormControl(''),
  //   password : new FormControl(''),
  // })


  async pruebaControl(){
    // console.log(this.password.value)
    // console.log(this.gourpForm.value)
  }

  ngOnInit() {
    this.src = appConstanst.logoUrl;
    this.loginTitle = 'Inicia Sesión'
    this.caption = 'Ingresa tu correo y contraseña'
    
  }

}

