import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { appConstanst } from 'src/app/app-common/constant/app-constans';
import { AdjustableHeightService } from 'src/app/app-common/services/adjustableHeight/adjustable-height.service';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-form-report',
  templateUrl: './form-report.component.html',
  styleUrls: ['./form-report.component.scss'],
})
export class FormReportComponent implements OnInit {

  // Variables for placeholder
  search = appConstanst.search
  placeholderBusiness = appConstanst.business
  placeholderInitialDate = appConstanst.initialDate
  placeholderFinalDate = appConstanst.finalDate
  textButton = appConstanst.search

  //Varibles

  business
  candidates : any = [];
  userSelected:any = [];

  // Variables for path
  pathBusiness = '/business'

  pathSearchCandidate = '/search_candidate'
  pathGetReport = '/valoration_response/'

  //Form control
  formSearch : FormGroup = new FormGroup({
    searchCandidate : new FormControl('')
  })


  constructor(
    private api : ApiService,
    private token : TokenService,
    private loadingController : LoadingController,
    private adjustHeight : AdjustableHeightService,
    private http1: HTTP,
    private http : HttpClient,
    private alertController : AlertController
  ) { }

  ngOnInit() {
    this.adjustHeight.adjustableHeightTabs(283+63)
    this.token.validToken()
    this.getBusiness()
    this.showLoadingTime()
    this.enableUser()
  }

  // function
  getReport(attemptId, name, documentNumber){
    this.api.dowunloadBlobPDF(name, documentNumber, this.token.get(), this.pathGetReport + attemptId)
    .then(res =>{
      this.alreadyUserAlert('Reporte descargado')
    })
    .catch(res =>{
      console.log('Error')
    })
  }

  enableUser(){
    this.userSelected = JSON.parse(localStorage.getItem('listCandidate'))
    if (this.userSelected != undefined){
      for (let i of this.userSelected){
        i.enable = true
      }
      this.api.put('/user_enable', this.userSelected, this.token.get())
      .subscribe((res =>{
        // this.getCandidates();
        localStorage.removeItem('listCandidate')
      }))
    }else{
      // this.getCandidates();
    }
  }

  // Query Api
  getBusiness(){
    this.api.get(this.pathBusiness, this.token.get())
    .subscribe((res => {
      this.business = res
    }))
    
  }

  //Buttons
  ApiSearchCandidate(){
    this.showLoading()
    if (this.formSearch.value.searchCandidate === ''){
      this.candidates = []
    }else{
      this.api.post(this.pathSearchCandidate, this.formSearch.value, this.token.get())
      .subscribe((res => {
        if (res !== appConstanst.not_exist_candidates){
          this.candidates = res;
        }else{
          this.candidates = []
          this.alreadyUserAlert('No exiten candidatos con ese nombre o número de documento')
        }
        this.loadingController.dismiss()
      }))
    }
  }

  // Loading
  async showLoadingTime() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
      duration : 400,
    });
    loading.present();
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
    });
    loading.present();
  }

  // Alert
  async alreadyUserAlert(message) {
    const alert = await this.alertController.create({
      header: 'Atención',
      message : message,
      cssClass: 'confirm-save-data-alert',
      buttons: [
        {
          text: 'OK',
        },
      ],
    });
    await alert.present();
  }

}
