import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { element } from 'protractor';
import { appConstanst } from 'src/app/app-common/constant/app-constans';
import { AdjustableHeightService } from 'src/app/app-common/services/adjustableHeight/adjustable-height.service';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';

@Component({
  selector: 'app-auth-template-candidates',
  templateUrl: './auth-template-candidates.component.html',
  styleUrls: ['./auth-template-candidates.component.scss'],
})
export class AuthTemplateCandidatesComponent implements OnInit {


  constructor(
    private apiService : ApiService,
    private tokenService : TokenService,
    private heightComponent : AdjustableHeightService,
    private loadingController : LoadingController,
    private alertController : AlertController,
    private activateRoute : ActivatedRoute,
  ) {
    if(screen.height >= 950){
      this.heightComponent.adjustableHeightTabs(345+63)
    }else{
      this.heightComponent.adjustableHeightTabs(345)
    }
    this.enable = JSON.parse(localStorage.getItem('listCandidate'))
  
   }

  ngOnInit() {
    this.tokenService.validToken()
    this.dateFilter = new Date().toISOString().substring(0,10)
    this.sesionSelected = JSON.parse(localStorage.getItem('sesion'))
    this.idSesion = this.sesionSelected.uuid //this.activateRoute.snapshot.paramMap.get('sesionId')
    // // // this.showLoading()
    // this.dateNow = this.date
    this.getSesions()
    this.enableUser();
    
    setTimeout(() => {
      this.getCandidates();
    }, 400);
  }

  //FormControl

  formSesion = new FormGroup({
    sesionIdSelected : new FormControl('')
  })

  //Variables
  users = [];
  candidates:any = [];
  date = new Date().toLocaleDateString('es', {year:"numeric", month:"long", day:"numeric"});
  dateNow = '';
  newCandidates:any;
  tam: string
  selected:any = [];
  uuidSelected:any = [];
  selectedUserBlocked:any = [];
  selectedUserEnable:any = [];
  listCandidatesBlocked:any = [];
  userSelected:any = [];
  boolCard = false;
  numberSelected: any;
  numberCandidate: any;
  enable:any;
  idSesion : any;
  placeholderSesion:string;
  dateFilter : any;
  sesions : any;
  sesionSelected : any;
  sesionId : any = [];
  loaded = true;
  arrayAttempts:any = []
  vacio:boolean = false

  // Object Json
  boolSelected:any = {
    selected: false
  }
  
  //Path variables
  
  pathTest:string = '/auth/tests/';
  getUserDetailPath = '/user_profile'
  pathFilterCandidate = '/sessions/candidate/'
  pathFilterSesion = '/sessions_filter'


  //fuctions

  getUsers(){
    const bodyUsers = {
      limit : 0,
      offset : 0
    }
    this.apiService.post('/users', bodyUsers, this.tokenService.get())
    .subscribe((res =>{
      this.users = res;
      }))
  }

  redirectTest(uuid:string){
    this.showLoading()
    location.href = this.pathTest + uuid
  }

  handleChange(event) {
    const query = event.target.value.toLowerCase();
    if (query.length == 0){
      this.newCandidates = this.candidates
    }
    this.newCandidates = this.candidates.filter(item => { return item.name.toLowerCase().includes(query) })
  }

  filter={
    withLinkSent: true
  }
  getCandidates(){
    this.apiService.get(this.pathFilterCandidate + this.idSesion, this.tokenService.get())
    .subscribe((res => {
      this.candidates = res
      if (this.candidates != appConstanst.not_exist_sesions){
        this.numberCandidate = res.length
        for (var i = 0; i < this.numberCandidate; i++){
          this.candidates[i] = Object.assign(this.candidates[i], this.boolSelected)
        }
        this.newCandidates = this.candidates
        this.loaded = false;
      }else{
        this.vacio = true
        this.loaded = false
        this.newCandidates = []
      }
      // this.loadingController.dismiss()
    }))
  }

  getSesions(){
    this.formSesion.setValue({
      sesionIdSelected : this.sesionSelected.name
    })
    this.sesionId = [];
    let filterSesion = {
      filterDate : this.dateFilter
    }
    this.placeholderSesion = this.sesionSelected.name
    this.apiService.post(this.pathFilterSesion, filterSesion, this.tokenService.get())
    .subscribe((res =>{
      if (res !== appConstanst.not_exist_sesions){
        this.sesions = res
        for (let i of res){
          this.sesionId.push(i.uuid)
        }
      }else{
        this.sesions = []
      }
    }))
  }

  getUser(){
    this.apiService.get(this.getUserDetailPath, this.tokenService.get())
    .subscribe((res=>{
    }))
  }

  select(i){
    
    var uuidCandidate = this.newCandidates[i].uuid
    var itemCandidate = {
      text : this.newCandidates[i].name,
      attemptId : this.newCandidates[i].attemptId,
      uuid : this.newCandidates[i].uuid,
      enable : null
    }
    this.newCandidates[i].selected = !this.newCandidates[i].selected
    if (this.uuidSelected.includes(uuidCandidate) == false){
      this.arrayAttempts.push(this.newCandidates[i].attemptId)
      this.uuidSelected.push(uuidCandidate)
      this.selected.push(itemCandidate)
    } else{
      var index = this.uuidSelected.indexOf(uuidCandidate)
      var indexAttemptId = this.arrayAttempts.indexOf(this.newCandidates[i].attemptId)
      this.arrayAttempts.splice(indexAttemptId, 1)
      this.uuidSelected.splice(index, 1)
      this.selected.splice(index, 1)
    }
    if (this.selected.length != 0){
      this.heightComponent.adjustableHeightTabs(345+108)
      this.boolCard = true
    } else{
      this.boolCard = false
      this.heightComponent.adjustableHeightTabs(345)
    }
    this.numberSelected = this.selected.length
    console.log(this.selected)
  }

  cancelSelected(){
    this.boolCard = false;
    this.heightComponent.adjustableHeightTabs(345);
    this.selected = [];
    this.uuidSelected = [];
    for(var i = 0; i < this.newCandidates.length; i++){
      this.newCandidates[i].selected = false
    }
  }

  enableUser(){
    this.userSelected = JSON.parse(localStorage.getItem('listCandidate'))
    if (this.userSelected != undefined){
      for (let i of this.userSelected){
        i.enable = true
      }
      this.apiService.put('/user_enable', this.userSelected, this.tokenService.get())
      .subscribe((res =>{
        // this.getCandidates();
        localStorage.removeItem('listCandidate')
      }))
    }else{
      // this.getCandidates();
    }
  }

  redirectTests(){
    if (this.selected.length != 0){
      this.userBlocked()
      localStorage.setItem('arrayAttempt', JSON.stringify(this.arrayAttempts))
      // localStorage.setItem('listCandidate', JSON.stringify(this.selected))
      //location.href = this.pathTest + this.selected[0].attemptId
    }
  }

  confirmRedirectTests(){
    if (this.selectedUserEnable != 0){
      localStorage.setItem('listCandidate', JSON.stringify(this.selectedUserEnable))
      for (let i of this.selectedUserEnable){
        i.enable = false
      }
      this.apiService.put('/user_enable', this.selectedUserEnable, this.tokenService.get())
      .subscribe((res =>{
        location.href = this.pathTest + this.selectedUserEnable[0].attemptId
      }))

    }
  }

  userBlocked(){
    var len = 0
    this.apiService.post('/user_enable', this.selected, this.tokenService.get())
    .subscribe((res =>{
      len = res.length
      for (let i of res){
        if (i.enable == false){
          this.listCandidatesBlocked.push(i)
        }else{
          this.selectedUserEnable.push(i)
        }
      }
      if (this.listCandidatesBlocked.length != 0){
        this.candidateBlockedAlert()
      }else{
        this.confirmRedirectTests()
      }
    }))
  }

  changeSesion(){
    if (this.sesionSelected.uuid !== this.sesions[this.formSesion.value.sesionIdSelected].uuid){
      localStorage.setItem('sesion', JSON.stringify(this.sesions[this.formSesion.value.sesionIdSelected]))
      location.reload()
    }
  }

  // Loading
  async showLoadingShort() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
      duration : 100,
    });
    loading.present();
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
    });
    loading.present();
  }

  //Alert
  async confirmCandidateAlert() {
    const alert = await this.alertController.create({
      header: 'Atención',
      subHeader : appConstanst.confirmCandidateSelected,
      message : appConstanst.continueUserSelected,
      cssClass: 'confirm-save-data-alert',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-no',
        },
        {
          text: 'Continuar',
          cssClass: 'alert-button-confirm',
          handler : () => {
            this.redirectTests()
          }
        },
      ],
    });
    await alert.present();
  }

  async candidateBlockedAlert() {
    let me:any = '';
    for (let i of this.listCandidatesBlocked){
      me = i.text + ', ' + me
    }
    const alert = await this.alertController.create({
      header: 'Atención',
      subHeader : appConstanst.userBlocked,
      message : me,//appConstanst.continueUserSelected,
      cssClass: 'confirm-save-data-alert',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-no',
        },
        {
          text: 'Continuar',
          cssClass: 'alert-button-confirm',
          handler : () => {
            this.confirmRedirectTests()
          }
        },
      ],
      
    });
    await alert.present();
  }

}
