export const errorLogin = 'El correo o la contrasena son incorrectos';
export const messageEmptyData = 'El correo y/o la contraseña no se encuentran diligenciados';
export const messageIncompletData = 'El correo o la contraseña no estan completos';
export const emailIncorrect = 'Correo invalido';
export const emailSend = 'Se ha enviado un correo con la nueva contraseña';

// Change password

export const currentPassword = 'Contraseña actual';
export const newPassword = 'Nueva contraseña';
export const confirmNewPassword = 'Confirma la nueva contraseña';

export const send = 'Enviar';

export const titleChangePassword = 'Cambio de contraseña';

export const updatePassword = 'Contraseña actualizada';
export const errorChangePassword = 'Error al actualizar la contraseña'
export const passwordDifferent = 'Las contraseñas no coinciden'
export const dataEmpty = 'Los datos están vacíos'

//Token
export const validToken = 'Token valido';

export const registerCandidate = {
  goBack : 'Regresar',
  previous : 'Anterior',
  save : 'Guardar',
  next : 'Siguiente',
  profileId : '196671b6-57e2-4818-be6e-84c09b071213',
  titleInformationBasic : 'Información básica',
  documentType : 'Tipo de documento',
  identificationId : 'Cédula de ciudadania',
  identityCard : 'Tarjeta de identidad',
  specialResidencePermit : 'Permiso especial de permanencia',
  foreignerId : 'Cédula de extranjeria',
  documentNumber : 'No. Documento',
  completeName : 'Nombre completo',
  dateBirth : 'Fecha de nacimiento',
  gender : 'Género',
  familyNucleus : 'Núcleo familiar',
  titleRegister : 'Registro de candidatos',
  nameImageRegister : 'Escanear',
  titleAddresContact : 'Domicilio y contacto',
  departament : 'Departamento',
  municipaly : 'Municipio',
  neighborhood : 'Barrio',
  Address : 'Dirección',
  phone : 'Telefono',
  email : 'Correo electrónico',
  titleMorphology : 'Morfología',
  height : 'Altura (cm)',
  weight : 'Peso (Kg)',
  dominantHand : 'Mano dominante',
  left : 'Izquierda',
  right : 'Derecha',
  titleInformationAcademic : 'Información académica',
  academicLevel : 'Nivel académico',
  graduationYear : 'Año de graduación',
  graduationInstitution : 'Institución de graduación',
  titleWorkingInformation : 'Información labora',
  experience : 'Experiencia',
  companyApply : 'Empresa a la cual aplica',
  business : 'Empresa',
  otherBusiness :'Otro',
  testToCarried : 'Pruebas a realizar',
  headerAlertTestToCarried : 'Atención',
  messageAlertTestToCarried : 'Selecciona las pruebas que va a realizar el candidato',
  validBusiness : 'Otra empresa',  
  nameIcon : 'git-compare-outline',
  testsSesion : 'Pruebas y sesiones',
  placeholderSesion : 'Sesiones',
  userSesionAdd : 'Datos asociados',
  errorDocumentTypes : 'No existen tipos de documentos',
  responseToken : 'Token valido',
  errorGender : 'Los datos estan vacio',
  errorDepartament : 'No existen datos',
  errorMunicipaly : 'No hay datos',
  errorDominantHand : 'No existen datos',
  errorAcademicLevel : 'No existen datos',
  errorBusiness : 'No existen empresas',
  errorSessions : 'No existen datos',
  errorTestGroup : 'Datos vacios',
  errorTests : 'La prueba no existe',
  alreadyUser : 'El número de documento ya se encuentra registrado',
  emailAlready : 'El correo electrónico ya se encuentra registrado',
  registerUserOk : 'Usuario registrado',
  registerConst : 'Registro de candidatos',
  ADD_DATEBIRTH : 'Ingrese la fecha de nacimiento',
  SELECT_DOCUMENT_TYPE : 'Seleccione un tipo de documento',
  SELECT_USER_GENDER : 'Seleccione un genero',
  SELECT_GEO_DEPARTAMENT : 'Seleccione un departamento',
  SELECT_GEO_MUNICIPALY : 'Seleccione un municipio',
  SELECT_USER_DOMINANT_HAND : 'Seleccione la mano dominante',
  SELECT_ACADEMIC_LEVEL : 'Seleccione el nivel académico',
  SELECT_BUSINESS : 'Seleccione la empresa',
  SELECT_TESTS : 'Seleccione una o varias pruebas',
  SELECT_SESION : 'Seleccione una sesión',
  numberChildren : 'Número de hijos'
  
}

export const habeasData = {
  title : 'Tratamiento de datos personales',
  titleCheckbox : 'Tratamiento de datos',
  see : 'Ver',
  paragraph_1 : 'El titular, quien además afirma ser titular de la información compartida, autoriza a CAUSA & EFECTO S.A.S, de forma libre, expresa, voluntaria y por escrito para que de acuerdo con lo dispuesto en la Ley 1581 de 2012 recolecta, recaude, almacene, use, circule, suprima, procese, compile, intercambie, dé tratamiento, actualice y disponga de sus datos personales que han sido suministrados en virtud de la presente solicitud, los cuales serán incorporados en distintas bases o bancos de datos de titularidad de CAUSA & EFECTO S.A.S. Dicha información será utilizada exclusivamente en el desarrollo de las funciones propias de su receptor, como empresa dedicada a la prestación de servicios de formación, selección por destreza y consultoría a pequeñas, medianas y grandes empresas. Para tales fines, CAUSA & EFECTO S.A.S podrá publicitar directa o indirectamente su información, siendo así que sus datos de contacto serán utilizados para el envío de comunicaciones, informes de selección por destreza, reportes, boletines, noticias u otros relacionados con sus servicios.',
  paragraph_2 : 'También autorizo para que se incluya en el proceso de Selección por destreza, para efectos de elaboración de informes, reproducción y comunicación pública; las fotografías, vídeos, grabaciones sonoras y registros en cualquier tipo de soporte, realizados durante y para el mencionado proceso, así como para utilizar mi imagen en el proceso para los fines y dentro de los propósitos establecidos por Causa & Efecto S.A.S. Los derechos aquí autorizados se dan sin limitación geográfica o territorial alguna. De igual forma la autorización de uso aquí establecida no implicará exclusividad, por lo que me reservo el derecho de otorgar autorizaciones de uso similares en los mismos términos en favor de terceros. La autorización que aquí se concede sobre este material es exclusiva para el proceso de Selección por destreza.',
  paragraph_3 : 'No obstante lo anterior, su titular podrá en cualquier momento, de forma expresa, directa, inequívoca y por escrito solicitarle a CAUSA & EFECTO S.A.S que sus datos sean suprimidos de las bases o banco de datos pertinentes al correo natalia.jaramillo@causayefecto.com.co.'
}

export const testQuestionsType = {
  InputFormNumber : 'InputFormNumber',
  number : 'number'
}