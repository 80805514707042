import { Component, OnInit } from '@angular/core';
import { registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-auth-template-register-candidate-information-basic',
  templateUrl: './auth-template-register-candidate-information-basic.component.html',
  styleUrls: ['./auth-template-register-candidate-information-basic.component.scss'],
})
export class AuthTemplateRegisterCandidateInformationBasicComponent implements OnInit {

  titleRegister = registerCandidate.titleRegister
  
  constructor() { }

  titleHeader = registerCandidate.titleRegister
  nameIcon = registerCandidate.nameIcon

  ngOnInit() {}

}
