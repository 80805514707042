import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-change-password',
  templateUrl: './feature-change-password.component.html',
  styleUrls: ['./feature-change-password.component.scss'],
})
export class FeatureChangePasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
