import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { validToken } from '../domain/constants/login-constants';

@Component({
  selector: 'app-feature-login',
  templateUrl: './feature-login.component.html',
  styleUrls: ['./feature-login.component.scss'],
})
export class FeatureLoginComponent implements OnInit {

  constructor(
    private apiService : ApiService,
    private tokenService : TokenService,
  ) { }

  validationToken(){
    this.apiService.get('/verification_token', this.tokenService.get())
    .subscribe((res =>{
      this.redirectToken(res)
    }))
  }

  redirectToken(response){
    if(response == validToken){
      location.href = '/auth/home'
    }
  }

  ngOnInit() {
    this.validationToken()
  }

}
