import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-template-report',
  templateUrl: './auth-template-report.component.html',
  styleUrls: ['./auth-template-report.component.scss'],
})
export class AuthTemplateReportComponent implements OnInit {

  @Input() titleHeader = 'Reporte'

  constructor() { }

  ngOnInit() {}

}
