import { Component, OnInit } from '@angular/core';
import { registerCandidate } from '../domain/constants/login-constants';

@Component({
  selector: 'app-feature-accept-habeas-data',
  templateUrl: './feature-accept-habeas-data.component.html',
  styleUrls: ['./feature-accept-habeas-data.component.scss'],
})
export class FeatureAcceptHabeasDataComponent implements OnInit {

  constructor() { }

  titleHeader = registerCandidate.titleRegister

  ngOnInit() {}

}
