import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConstanst } from '../../constant/app-constans';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { File } from '@awesome-cordova-plugins/file';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  api = appConstanst.api;

  constructor(
    private http: HttpClient,
    private http1: HTTP
  ) { }

  post(path:string, body:any, token:string){
    const headerToken = new HttpHeaders(
      {
        Authorization : 'Bearer ' + token
      }
    )
    return this.http.post<any>(this.api + path, body, {headers : headerToken})
  }

  get(path:string, token:string){
    const headerToken = new HttpHeaders(
      {
        Authorization : 'Bearer ' + token
      }
    )
    return this.http.get<any>(this.api + path, {headers : headerToken})
  }
  
  getDocument(path:string, token){
    const headerToken = new HttpHeaders(
      {
      Authorization : 'Bearer ' + token
      })
      return this.http.get(this.api + path, {responseType : 'blob' , headers : headerToken})
  }

  dowunloadBlobPDF(name : string, documentNumber : string, token, path : string){
    name = name.replace(/ /g, '_')
    const headerToken = 
      {
        Authorization : 'Bearer ' + token,  
      }
    return this.http1.downloadFile(this.api + path, null, headerToken, File.externalDataDirectory + name + '_' + documentNumber +'.pdf')
  }

  put(path:string, body:any, token:string){
    const headerToken = new HttpHeaders(
      {
        Authorization : 'Bearer ' + token
      }
    )
    return this.http.put<any>(this.api + path, body, {headers : headerToken})
  }


  delete(path:string, parameter:string, token:string){
    const headerToken =new HttpHeaders(
      {
        Authorization : 'Bearer ' + token
      }
    )
    return this.http.delete<any>(this.api + path + '/' + parameter, {headers : headerToken})
  }
}
