import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-register-candidate',
  templateUrl: './feature-register-candidate.component.html',
  styleUrls: ['./feature-register-candidate.component.scss'],
})
export class FeatureRegisterCandidateComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
