import { Component, Input, OnInit } from '@angular/core';
import { appConstanst } from '../../constant/app-constans';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  @Input() src;

  constructor() { }

  ngOnInit() {}


}
