import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-description-sesion',
  templateUrl: './description-sesion.component.html',
  styleUrls: ['./description-sesion.component.scss'],
})
export class DescriptionSesionComponent implements OnInit {
  
  constructor() { }
  
  ngOnInit() {}
  
  @Input() nameSesion = '';
  @Input() citySesion = '';
  @Input() departamentSesion = '';
  @Input() addressSesion = '';
  @Input() initialTime = '';
  @Input() finalTime = '';
  @Input() finalDate = '';
  
  initialTimeLabel:string = 'Inicio:';
  finalTimeLabel:string = 'Fin:'
}
