import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss'],
})
export class AuthPage implements OnInit {

  email : string;
  password : string;

  constructor(public toastController: ToastController) { }

  async presentToast(email) {
    const toast = await this.toastController.create({
      message: email,
      duration: 2000
    });
    toast.present();
  }

  ngOnInit() {
  }

}
