import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-form-register-candidate-address-contact',
  templateUrl: './form-register-candidate-address-contact.component.html',
  styleUrls: ['./form-register-candidate-address-contact.component.scss'],
})
export class FormRegisterCandidateAddressContactComponent implements OnInit {

  constructor(
    private token : TokenService
  ) { }

  titleAddresContact = registerCandidate.titleAddresContact;
  departament = registerCandidate.departament;
  municipaly = registerCandidate.municipaly;
  neighborhood = registerCandidate.neighborhood;
  address = registerCandidate.Address;
  phone = registerCandidate.phone;
  emailPage = registerCandidate.email;
  morphology = registerCandidate.titleMorphology;
  height = registerCandidate.height;
  weight = registerCandidate.weight;
  dominantHand = registerCandidate.dominantHand;
  left = registerCandidate.left;
  right = registerCandidate.right

  goBack = registerCandidate.goBack;
  next = registerCandidate.next

  goBackPage = '/auth/information-basic';
  nextPage = '/auth/information-academic';
  data:any;
  register:any;

  formInput = new FormGroup({
    departament : new FormControl(),
    municipaly : new FormControl(),
    neighborhood : new FormControl(),
    address : new FormControl(),
    phone : new FormControl(),
    email : new FormControl(),
    height : new FormControl(),
    weight : new FormControl(),
    dominantHand : new FormControl()
  })


  redirectGoBack(){
    location.href = this.goBackPage;
    
  }

  redirectNext(){
    const confirmEmail = {
      confirmEmail : this.formInput.value.email
    }
    this.data = Object.assign(JSON.parse(localStorage.getItem('register')), this.formInput.value, confirmEmail)
    localStorage.setItem('register', JSON.stringify(this.data))
    location.href = this.nextPage;
  }

  printData(){
    console.log(this.data)
  }

  setDataFormInput(){
    this.register = JSON.parse(localStorage.getItem('register'))
    if (this.register.departament != undefined){
      this.formInput.setValue({
        departament : this.register.departament,
        municipaly : this.register.municipaly,
        neighborhood : this.register.neighborhood,
        address : this.register.address,
        phone : this.register.phone,
        email : this.register.email,
        height : this.register.height,
        weight : this.register.weight,
        dominantHand : this.register.dominantHand
      })
    }
  }

  ngOnInit() {
    this.token.validToken()
    this.setDataFormInput()
  }

}
