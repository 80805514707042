import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { emailIncorrect, emailSend } from '../../constants/login-constants';

@Component({
  selector: 'app-form-recovery-password',
  templateUrl: './form-recovery-password.component.html',
  styleUrls: ['./form-recovery-password.component.scss'],
})
export class FormRecoveryPasswordComponent implements OnInit {

  position = 'stacked';
  color = 'tertiary';
  type = 'email';
  text = 'EMAIL'

  formInput = new FormGroup({
    email : new FormControl('', Validators.email),
  })

  constructor(
    private apiService : ApiService,
    private alertController : AlertController,
    private tokenService : TokenService,
  ) { }

  recoveryButton(){
    if (this.formInput.valid && this.formInput.value.email.length != 0){
      this.apiService.put('/recovery_password', this.formInput.value, this.tokenService.get())
      .subscribe((res) =>{
        console.log(res);
        this.recoveryPassword(res.toString());
      });   
    }else{
      this.alert(emailIncorrect)
    }
  }

  recoveryPassword(response:string){
    if (response != emailIncorrect){
      this.alertRedirect(emailSend)
    }else{
      this.alert(emailIncorrect)
    }
  }

  redirect(){
    location.href = '/auth/login';
  }

  async alert(messageAlert:string){
    const alert = await this.alertController.create({
      message : messageAlert,
      buttons : ['Ok']
    });
    alert.present()
  }

  async alertRedirect(messageAlert:string){
    const alert = await this.alertController.create({
      header : '',
      subHeader : '',
      message : messageAlert,
      buttons : [{
        text : 'Ok',
        handler : () => {
          this.redirect();
        }
      }],
    });
    alert.present();
  }

  ngOnInit() {
    this.tokenService.validToken()
  }

}
