import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';

@Component({
  selector: 'app-form-tests-candidate',
  templateUrl: './form-tests-candidate.component.html',
  styleUrls: ['./form-tests-candidate.component.scss'],
})
export class FormTestsCandidateComponent implements OnInit {

  constructor(
    private api : ApiService,
    private activateRouter : ActivatedRoute,
    private token : TokenService,
  ) { }
  

  ngOnInit() {
  }

}
