import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { confirmNewPassword, currentPassword, dataEmpty, errorChangePassword, newPassword, passwordDifferent, send, titleChangePassword, updatePassword } from '../../constants/login-constants';

@Component({
  selector: 'app-form-change-password',
  templateUrl: './form-change-password.component.html',
  styleUrls: ['./form-change-password.component.scss'],
})
export class FormChangePasswordComponent implements OnInit {

  currentPasswordInput = currentPassword;
  newPasswordInput = newPassword;
  confirmNewPasswordInput = confirmNewPassword;
  titlePage = titleChangePassword;
  send = send;
  path = '/change_password';

  formInput = new FormGroup(
    {
      actualPassword : new FormControl('', Validators.minLength(8)),
      newPassword : new FormControl('', Validators.minLength(8)),
      confirmNewPassword : new FormControl('', Validators.minLength(8)),
    }
  )

  printData(){
    console.log(this.formInput.value)
  }

  constructor(
    private apiService : ApiService,
    private tokenService : TokenService,
    private alertController : AlertController,
    ) { }
    
  changePassword(){
    if(this.formInput.value.actualPassword.length == 0 && this.formInput.value.newPassword.length == 0 && this.formInput.value.confirmNewPassword.length == 0){
      this.alert(dataEmpty);
    }else{
      if(this.formInput.value.newPassword == this.formInput.value.confirmNewPassword){
        this.apiService.put(this.path, this.formInput.value, this.tokenService.get())
        .subscribe((res =>
          {
            this.validResponse(res)
          }))
      }else{
        this.alert(passwordDifferent)
      }
    }
  }

  validResponse(response){
    if (response == updatePassword){
      this.alertRedirect(updatePassword)
    }else{
      this.alert(response)
    }
  }

  async alertRedirect(messageAlert){
    const alert = await this.alertController.create({
      message : messageAlert,
      buttons : [{
        text : 'Ok',
        handler : () => {location.href = '/auth/home'}
      }],
    });
    await alert.present();
  }

  async alert(messageAlert){
    const alert = await this.alertController.create({
      message : messageAlert,
      buttons : ['Ok'],
    });
    await alert.present();
  }

  ngOnInit() {
    this.tokenService.validToken()
  }

}
