import { Component } from '@angular/core';
import { ApiService } from './app-common/services/api/api.service';
import { TokenService } from './app-common/services/token/token.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title : 'Inicio', url : '/auth/home', icon : 'home'},
    { title : 'Pruebas', url : '/auth/sesions', icon : 'menu'},
    { title : 'Reports', url : '/auth/report', icon : 'stats-chart'},
    { title : 'Cambiar contraseña', url : '/auth/change-password', icon : 'key'},
  ];
  constructor(
    private api : ApiService,
    private token : TokenService,
  ) {}

  logout(){
    this.api.get('/logout', this.token.get())
    .subscribe((res =>{
      console.log(res)
      location.href = '/auth/login'
    }))
    
  }
}
