import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { errorLogin, messageEmptyData, messageIncompletData } from '../../constants/login-constants';
import { appConstanst } from 'src/app/app-common/constant/app-constans';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss'],
})
export class FormLoginComponent implements OnInit {

  @Input() text;
  @Input() type;
  @Input() position;

  nameEmail = 'EMAIL';
  namePassword = 'CONTRASEÑA';
  forgetPassword = '¿Olvidaste tu contraseña?';
  token:string;
  tokenn = new FormControl();

  users:any = [];

  formInput: FormGroup = new FormGroup({
    email : new FormControl('',Validators.email),
    password : new FormControl('', Validators.minLength(6)),
  })
  constructor(
    private alertController: AlertController,
    private apiServices: ApiService,
    private tokenService: TokenService,
    private loadingController : LoadingController,
  ) { }

  loginButton(){
    if (this.formInput.value.email.length != 0 && this.formInput.value.password.length != 0){
      if (this.formInput.valid){
        this.showLoading()
        this.apiServices.post('/login',this.formInput.value, this.tokenService.get())
        .subscribe((res => (
          this.token = res.toString(),
          this.tokenService.set(this.token),
          this.login(this.token)
        )))
      } else{
        this.alert(messageIncompletData)
      }
    } else{
      this.alert(messageEmptyData)
    }
  }

  testGet(){
    this.apiServices.get('/users', this.getToken())
    .subscribe((data => (
      this.users.push(data),
      console.log(this.users)
      )))
  }

  login(response:string){
    if(errorLogin != response){
      console.log('Entro');
      location.href = '/auth/home'
    } else{
      this.alert(errorLogin);
    }
  }

  getToken(){
    return this.tokenService.get()
  }
  
  async alert(messageAlert:string){
    this.loadingController.dismiss()
    const alert = await this.alertController.create({
      header: '',
      subHeader: '',
      message: messageAlert,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
    });
    loading.present();
  }

  

  ngOnInit() {
  }

}
