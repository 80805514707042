import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
})
export class EmailInputComponent implements OnInit {

  constructor() { }

  @Input() text:string = 'Eimal';
  @Input() email:FormGroup;
  @Input() label:string;

  

  ngOnInit() {}

}
