import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards-select-candidate',
  templateUrl: './cards-select-candidate.component.html',
  styleUrls: ['./cards-select-candidate.component.scss'],
})
export class CardsSelectCandidateComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  print(){
    console.log('hola')
  }

  printN(){
    console.log('No')
  }
}
