import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActionSheetController, IonPopover, IonSelect, LoadingController, ModalController } from '@ionic/angular';
import { appConstanst } from 'src/app/app-common/constant/app-constans';
import { AdjustableHeightService } from 'src/app/app-common/services/adjustableHeight/adjustable-height.service';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';

@Component({
  selector: 'app-auth-template-tests-candidate',
  templateUrl: './auth-template-tests-candidate.component.html',
  styleUrls: ['./auth-template-tests-candidate.component.scss'],
})
export class AuthTemplateTestsCandidateComponent implements OnInit {

  @ViewChild('myPopover') popover: IonPopover;
  @ViewChild('miSelect') selectRef: IonSelect;



  constructor(
    private api : ApiService,
    private token : TokenService,
    private activateRouter : ActivatedRoute,
    private heightcomponent : AdjustableHeightService,
    private loadingController : LoadingController,
    private actionSheetController : ActionSheetController,
    private modalController: ModalController,
  ) { 
    this.getAttempt()
  }

  pathGetUserDetail:string = '/user_detail/'
  pathGetAttempt:string =  '/tests/evaluator/questions/' //'/test_attempt/'
  pathTestQuestions:string = '/auth/test-questions/'
  pathTest:string = '/auth/tests/';
  pathAuthSesion:string = '/auth/sesions';
  pathUserEnable:string = '/user_enable';

  len:any;

  nameUser:string;
  lastNameUser:string
  business:string;
  dominantHand:string;
  userId:any;
  tests:any;
  listCandidate:any;
  iconStart = 'construct-outline'
  selectedOtherUser = appConstanst.selectedOtherUser
  arrayAttempt:any = []
  users:any = []
  indexCandidate:number = 0

  nameTest:string
  questions:any
  options:any
  questionType:string
  indexTest:number

  title:string;
  titleHeader = 'Pruebas individuales'

  candidate = new FormControl()
  
  uuidAttempt:string;
  user:any;

  ngOnInit() {
    this.token.validToken()
    this.heightcomponent.adjustableHeightTabs(191+66) // 191
    // this.showLoading()
    // this.getAttempt()
  }

  changeCandidate(i:number){
    this.indexCandidate = i
    this.nameUser = this.users[this.indexCandidate].name
    this.business = this.users[this.indexCandidate].business
    this.dominantHand = this.users[this.indexCandidate].dominantHand
    this.tests = this.users[this.indexCandidate].tests
    this.tests.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
    });
  }

  getAttempt(){
    this.arrayAttempt = JSON.parse(localStorage.getItem('arrayAttempt'))
    this.api.post('/tests/questions', {arrayAttempts : this.arrayAttempt}, this.token.get())
    .subscribe(res => {
      this.users = res
      this.nameUser = this.users[this.indexCandidate].name
      this.business = this.users[this.indexCandidate].business
      this.dominantHand = this.users[this.indexCandidate].dominantHand
      this.tests = this.users[this.indexCandidate].tests
      this.tests.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
      });

    })
    
    // this.uuidAttempt = this.activateRouter.snapshot.paramMap.get('uuid')
    // this.api.get(this.pathGetAttempt + this.uuidAttempt, this.token.get())
    // .subscribe((res =>{
    //   if (res != 'El attempt no existe'){
    //     this.len = res.tests.length
    //     this.userId = res.user.uuid;
    //     this.lastNameUser = res.user.lastName
    //     if (this.lastNameUser != undefined){
    //       this.nameUser = res.user.name + ' ' + res.user.lastName;
    //     }else{
    //       this.nameUser = res.user.name;
    //     }
    //     this.tests = res.tests;
    //     this.tests.sort((x, y) => x.name.localeCompare(y.name));
    //     this.business = res.user.business
    //     this.dominantHand = res.user.dominantHand
    //     this.uuidAttempt = res.user.attemptId
    //     // this.loadingController.dismiss()
    //   }
    // }))
  }

  redirectTestQuestions(i){
    let auxnameUser = {
      nameUser : this.nameUser
    }
    localStorage.setItem('questions', JSON.stringify(Object.assign(this.tests[i], auxnameUser)))
    location.href = this.pathTestQuestions + this.userId + '/' + this.uuidAttempt;
    // this.showLoading()
    // setTimeout(() => {
    // }, 1000);
  }

  redirectCandidateZero(){
    this.nameUser = this.users[0].name
    this.business = this.users[0].business
    this.dominantHand = this.users[0].dominantHand
    this.tests = this.users[0].tests
    this.tests.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
    });
  }

  redirectCandidate(index){
    this.indexCandidate = index
    this.nameUser = this.users[index].name
    this.business = this.users[index].business
    this.dominantHand = this.users[index].dominantHand
    this.tests = this.users[index].tests
    this.tests.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
    });
    // this.indexCandidate = this.users.findIndex(user => user.attemptId === id)
    // this.nameUser = this.users[this.indexCandidate].name
    // this.business = this.users[this.indexCandidate].business
    // this.dominantHand = this.users[this.indexCandidate].dominantHand
    // this.tests = this.users[this.indexCandidate].tests
    // if (id != this.uuidAttempt){
    //   this.showLoadingShort()
    //   location.href = this.pathTest + id
    // }
  }


  async showLoadingShort() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
      duration : 300,
    });
    loading.present();
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
    });
    loading.present();
  }

  // Action sheet
  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'my-custom-class',
      buttons: this.listCandidate,
    });

    actionSheet.present();
  }

  removeCandidate(){
    // let auxLengthCandidate = this.listCandidate.length;
    // if (auxLengthCandidate != 0){
    //   let auxAttemptId = this.activateRouter.snapshot.paramMap.get('uuid');
    //   let indexUser = this.listCandidate.findIndex(x => x.attemptId === this.users[this.indexCandidate].attemptId)
    //   this.userEnable(this.listCandidate[indexUser].uuid, indexUser)
    //   this.listCandidate.splice(indexUser, 1);
    //   this.users.splice(this.indexCandidate, 1)
    //   localStorage.setItem('arrayAttempt', JSON.stringify(this.arrayAttempt))
    //   localStorage.setItem('listCandidate', JSON.stringify(this.listCandidate))
    //   this.redirectCandidateZero()
    // }
    let auxLengthArrayAttempts = this.users.length
    if(auxLengthArrayAttempts != 0){
      this.arrayAttempt.splice(this.indexCandidate, 1)
      this.userEnable(this.users[this.indexCandidate].uuid)
      localStorage.setItem('arrayAttempt', JSON.stringify(this.arrayAttempt))
      this.users.splice(this.indexCandidate, 1)
      try{
        this.redirectCandidateZero()
      }catch(error){

      }
      if(this.users.length == 0){
        location.href = this.pathAuthSesion
      }
    }    
  }

  userEnable(userId:string){
    let auxLength = this.users.length
    let auxUserEnable = [
      {
        uuid : userId,
        enable : true
      }]
      this.api.put(this.pathUserEnable, auxUserEnable, this.token.get())
      .subscribe((res =>{
        // if(auxLength != 0){
        //   this.redirectCandidateZero()
        //   // location.href = this.pathTest + this.listCandidate[0].attemptId
        // }else{
        //   location.href = this.pathAuthSesion
          
        // }
        // if(this.users.length < 0){
        //   location.href = this.pathAuthSesion
        // }
        if(this.users.length == 0){
        }
      }))

    }

  openPopover(index: number) {
    this.popover.present();
    this.indexTest = index
    this.nameTest = this.tests[index].name
    this.questions = this.tests[index].questions
  }

  sendResponse(){
    this.tests[this.indexTest].complete = true
    let postResponse = []
    for(let question of this.tests[this.indexTest].questions){
      const aux = {
        userId : this.users[this.indexCandidate].uuid,
        questionId : question.uuid,
        attemptId : this.users[this.indexCandidate].attemptId,
        value : question.value,
        timeResponse : 0,
        questionOptionId : ""
      }
      postResponse.push(aux)
    }
    this.api.post('/test/attempt/'+ this.users[this.indexCandidate].attemptId +'/receipt_form', postResponse, this.token.get())
    .subscribe(res =>{
      if (res === 'Datos agregados'){
        this.tests.splice(this.indexTest, 1)
      }

    })
    this.popover.dismiss();
  }

  abrirSelect() {
    this.selectRef.open();
  }

  onSelectChange(index: number) {
    this.changeCandidate(index)
  }

    
}
