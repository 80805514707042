import { Component, OnInit } from '@angular/core';
import { titleChangePassword } from '../../constants/login-constants';

@Component({
  selector: 'app-auth-template-change-password',
  templateUrl: './auth-template-change-password.component.html',
  styleUrls: ['./auth-template-change-password.component.scss'],
})
export class AuthTemplateChangePasswordComponent implements OnInit {

  constructor() { }

  titlePage = titleChangePassword;

  ngOnInit() {}

}
