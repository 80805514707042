import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http'
import { AuthModule } from './auth/auth.module';
import { AppCommonModule } from './app-common/app-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP } from "@awesome-cordova-plugins/http/ngx"
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(),
    AppRoutingModule, 
    HttpClientModule, 
    AuthModule, 
    AppCommonModule,
    ReactiveFormsModule,
    FormsModule,
    //NavModule
    ],
  providers: [
    BarcodeScanner,
    HTTP,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
