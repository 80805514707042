import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormControlName, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {

  @Input() formInput: FormGroup;
  @Input() label: string;
  @Input() text: string;
  @Input() position: string;
  @Input() type: string;



  constructor() { }

  ngOnInit() {}

}
