import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { appConstanst } from 'src/app/app-common/constant/app-constans';
import { ApiService } from 'src/app/app-common/services/api/api.service';
import { TokenService } from 'src/app/app-common/services/token/token.service';
import { registerCandidate } from '../../constants/login-constants';

@Component({
  selector: 'app-auth-template-home',
  templateUrl: './auth-template-home.component.html',
  styleUrls: ['./auth-template-home.component.scss'],
})
export class AuthTemplateHomeComponent implements OnInit {

  constructor(
    private api : ApiService,
    private token : TokenService,
    private loadingController : LoadingController,
  ) { }

  ngOnInit() {
    this.token.validToken()
    this.enableUser()
  }


  icon1 = 'person-add-outline';
  title1 = 'Registro';
  caption1 = 'De candidatos';
  labelEmail = 'Email'
  
  icon2 = 'ribbon-outline';
  title2 = 'Iniciar';
  caption2 = 'Pruebas de la sesión';

  candidates = '/auth/candidates';
  sesions = '/auth/sesions'
  register = '/auth/register'
  acceptHabeasData = '/auth/accept-habeas-data'

  titleHeader = 'Inicio'
  nameIcon = registerCandidate.nameIcon

  userSelected:any = [];

  redirectCandidates(){
    this.showLoading();
    setTimeout(() => {
      location.href = this.sesions;
    }, 1000);
  }

  redirectRegister(){
    this.showLoading()
    setTimeout(() => {
      location.href = this.register;
    }, 1000);
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: appConstanst.loading,
      duration : 1000,
    });
    loading.present();
  }

  enableUser(){
    this.userSelected = JSON.parse(localStorage.getItem('listCandidate'))
    if (this.userSelected != undefined){
      for (let i of this.userSelected){
        i.enable = true
      }
      this.api.put('/user_enable', this.userSelected, this.token.get())
      .subscribe((res =>{
        // this.getCandidates();
        localStorage.removeItem('listCandidate')
      }))
    }else{
      // this.getCandidates();
    }
  }

  
}
